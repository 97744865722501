<template>
  <v-dialog
    v-model="dialogActiveState"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark :color="`${colorTheme} lighten-1`">
        <v-btn icon dark @click="dialogActiveState = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialogActiveState = false"> Cerrar</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- ----------------------------------------------------- -->
      <v-container v-if="observations?.length < 1">
        <v-row class="my-15">
          <v-col class="mx-auto d-flex flex-column" cols="12" sm="10">
            <div class="mx-auto mb-4 pa-8 rounded-circle green lighten-4">
              <v-icon large color="success">mdi-check-circle-outline</v-icon>
            </div>
            <div>
              <p class="text-center font-weight-bold text-h6 mb-1">
                ¡Todo Bien!
              </p>
              <p class="text-center font-weight-medium text-body-1">
                No cuenta con observaciones y/o llamados de atención.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- ----------------------------------------------------- -->

      <!------------ Here goes the the tab container -------------->
      <v-container v-else>
        <v-row>
          <v-col
            cols="12"
            class="d-flex flex-column justify-center align-center"
          >
            <p class="text-h4 mb-2 mt-4 text-center">Observaciones</p>
            <v-chip class="my-2" dense :color="`${colorTheme} lighten-4`">
              <span
                :class="`font-weight-bold text-body-1 mb-0 ${colorTheme}--text`"
                >Desde {{ dateRange?.startDate }}
                <v-icon small>mdi-arrow-right-circle-outline</v-icon> Hasta
                {{ dateRange?.endDate }}</span
              >
            </v-chip>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(observation, index) in observations"
                :key="index"
              >
                <v-expansion-panel-header v-slot="{ open }">
                  <v-row>
                    <!-- Observation Date -->

                    <v-col cols="12" class="text--secondary">
                      <v-fade-transition leave-absolute hide-on-leave>
                        <p
                          class="text-body-1 font-weight-medium mb-0"
                          v-if="!open"
                        >
                          {{
                            fullFormattedDate(
                              observation.date,
                              "dddd D [de] MMMM [del] YYYY"
                            )
                          }}
                        </p>
                        <v-row v-else>
                          <v-col cols="12">
                            <p
                              class="text-body-1 font-weight-bold text-left mb-0 black--text"
                            >
                              {{ observation.user_data?.first_name }}
                              {{ observation.user_data?.last_name }}
                              <span class="d-none d-sm-inline">-</span>
                              <br class="d-sm-none" />
                              {{
                                fullFormattedDate(
                                  observation.date,
                                  "dddd D [de] MMMM [del] YYYY"
                                )
                              }}
                            </p>
                          </v-col>
                        </v-row>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row justify="space-around" no-gutters>
                    <v-card class="elevation-0">
                      {{ observation.observation }}
                    </v-card>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex justify-end" cols="12">
                      <v-btn
                        v-if="existInArray('Editar', currentPageActions)"
                        color="amber lighten-4"
                        elevation="0"
                        :disabled="deleteStatus"
                        :loading="updateStatus"
                        class="white--text p-2 mr-2"
                        @click="openUpdateObservationModal(observation)"
                        ><span class="orange--text font-weight-bold"
                          >Modificar</span
                        ></v-btn
                      >
                      <v-btn
                        v-if="existInArray('Eliminar', currentPageActions)"
                        color="red lighten-4"
                        elevation="0"
                        :disabled="updateStatus"
                        :loading="deleteStatus"
                        @click="deleteObservation(observation)"
                        class="white--text p-2"
                        ><span class="red--text font-weight-bold"
                          >Eliminar</span
                        ></v-btn
                      >
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { mapGetters } from "vuex";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal";
import observationRepository from "@/repositories/observationRepository.js";
import Swal from "sweetalert2";
export default {
  name: "ObservationsModal",
  props: {
    observations: {
      type: Array,
    },
    selectedStage: {
      type: Object,
    },
    dateRange: {
      type: Object,
    },
    colorTheme: {
      type: String,
    },
  },
  components: {
    DeleteBaseModal,
  },
  mounted() {
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Panel disciplinario");
  },
  data() {
    return {
      dialogActiveState: false,
      deletingObservation: false,
      deleteStatus: false,
      updateStatus: false,
    };
  },
  methods: {
    deleteObservation(observation) {
      Swal.fire({
        title: "Eliminar observación",
        text: "¿Realmente desea eliminar la observación?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteStatus = true;

          //Deleting observation on DB
          observationRepository
            .deleteObservation(observation.id)
            .then((response) => {
              //deleting item locally after the request hass been made successfully
              const observationIndex = this.observations.indexOf(observation);
              this.$emit("removeObservation", { index: observationIndex });
              this.fireToast({
                icon: "success",
                title: "La observación se ha eliminado exitosamente",
              });
            })
            .catch((e) => {
              this.fireToast({
                title: "Ocurrió un error al eliminar la observación",
                icon: "error",
              });
            })
            .finally(() => {
              this.deleteStatus = false;
              this.dialogActiveState = true;
            });
        }
      });
    },
    openUpdateObservationModal(observation) {
      this.$emit("openUpdateObservationModal", observation);
    },
    toggleModal() {
      this.dialogActiveState = !this.dialogActiveState;
    },

    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
    allObservationsSorted() {
      // Checking if there are not undefined properties
      if (this.correctObservationsListStructure) {
        return this.mergeSort(this.observations, "date", "desc", "date");
      }

      // return this.observations;
    },

    // verifying main object is not null or undefined
    correctObservationsListStructure() {
      return this.observations !== undefined && this.observations !== null;
    },
  },

  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>