import { PedagogicalDiaryService } from "@/core/services/apis.service";

/**
* GET request to get discipline info
* @param studentUUID
* @param startDate
* @param endDate
* @returns {*}
*/

export const getStudentDisciplineDetailsByTimePeriod = (studentUUID, startDate, endDate) => {
  return PedagogicalDiaryService.get(`student-class/report/studentId/${studentUUID}/startDate/${startDate}/endDate/${endDate}`);
};

/**
* GET request to get stages sorted and current stage
* @param gradeId
* @returns {*}
*/
export const getStudentStagesByGrade = (gradeId) => {
  return PedagogicalDiaryService.get(`dashboard/year-progress/grade/${gradeId}/speciality/8`
  );
};

export default {
  getStudentDisciplineDetailsByTimePeriod,
  getStudentStagesByGrade
}