<template>
  <v-dialog
    v-model="dialogActiveState"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark :color="`${colorTheme} lighten-1`">
        <v-btn icon dark @click="dialogActiveState = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialogActiveState = false"> Cerrar </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container v-if="nursingVisits?.length < 1">
        <v-row class="my-15">
          <v-col class="mx-auto d-flex flex-column" cols="12" sm="10">
            <div class="mx-auto mb-4 pa-8 rounded-circle green lighten-4">
              <v-icon large color="success">mdi-check-circle-outline</v-icon>
            </div>
            <div>
              <p class="text-center font-weight-bold text-h6 mb-1">
                ¡Todo Bien!
              </p>
              <p class="text-center font-weight-medium text-body-1">
                No cuenta con visitas a unidad de primeros auxilios.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="px-lg-15" v-else>
        <v-row>
          <v-col
            cols="12"
            class="d-flex flex-column justify-center align-center"
          >
            <p class="text-h4 mb-2 mt-4 text-center">
              Visitas a unidad de primeros auxilios
            </p>
            <v-chip class="my-2" dense :color="`${colorTheme} lighten-4`">
              <span
                :class="`font-weight-bold text-body-1 mb-0 ${colorTheme}--text`"
                >Desde {{ dateRange?.startDate }}
                <v-icon small>mdi-arrow-right-circle-outline</v-icon> Hasta
                {{ dateRange?.endDate }}</span
              >
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="studentVisitsHeaders"
              :items="formattedStudentsClinicVisits"
              :expanded.sync="expandedStudents"
              :items-per-page="-1"
              item-key="visit_id"
              :single-expand="true"
              show-expand
            >
              <!-- Student information -->
              <template v-slot:[`item.full_name`]="{ item }">
                <div class="py-2">
                  <div class="d-flex">
                    <!-- Student photo -->
                    <div>
                      <v-avatar size="70" color="red lighten-5">
                        <!-- <img :src="item.photo" :alt="item.full_name" /> -->
                        <v-icon color="red">mdi-account</v-icon>
                      </v-avatar>
                    </div>
                    <!-- Student Name and Code -->
                    <div class="ml-3 d-flex flex-column justify-center">
                      <p class="mb-0 text-h6 font-weight-bold">
                        {{ item.code }}
                      </p>
                      <p class="mb-0 text-body-1 font-weight-medium">
                        {{ item.full_name }}
                      </p>
                      <p
                        class="mb-0 text-body-1 font-weight-medium grey--text text--darken-1"
                      >
                        {{ item.grade }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>

              <!-- Visit date -->
              <template v-slot:[`item.visit_date`]="{ item }">
                <p class="text-body-1 font-weight-medium mb-0">
                  {{
                    fullFormattedDate(
                      item.visit_date,
                      "dddd D [de] MMMM [del] YYYY [a las] h:mm A"
                    )
                  }}
                </p>
              </template>

              <!-- Collapsable content -->
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-row class="my-5 mx-2">
                    <v-col cols="12">
                      <p class="text-body-1 font-weight-bold">Detalle:</p>
                      <p class="text-body-1 font-weight-medium">
                        {{ item.diagnostic }}
                      </p>
                    </v-col>

                    <v-row>
                      <v-col cols="12" md="4">
                        <v-card
                          outlined
                          class="rounded-lg pa-5 d-flex flex-column align-center"
                        >
                          <v-avatar
                            size="80"
                            color="pink lighten-5"
                            class="mb-3"
                          >
                            <v-icon large color="pink"
                              >mdi-pill-multiple</v-icon
                            >
                          </v-avatar>
                          <div
                            class="d-flex flex-column align-start"
                            v-if="item.medication?.length > 0"
                          >
                            <p class="text-h6 font-weight-bold">
                              Medicamentos suministrados
                            </p>
                            <ul class="my-2">
                              <li
                                v-for="(element, index) in item.medication"
                                :key="index"
                              >
                                <span class="font-weight-bold"
                                  >{{ element.medicine }}:
                                </span>
                                <span class="font-weight-medium">{{
                                  element.dosis_supplied
                                }}</span>
                              </li>
                            </ul>
                          </div>

                          <div v-else>
                            <p class="text-h6 font-weight-medium text-center">
                              No se suministraron medicamentos.
                            </p>
                          </div>
                        </v-card>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-card
                          outlined
                          class="rounded-lg pa-5 d-flex flex-column align-center"
                        >
                          <v-avatar
                            size="80"
                            color="purple lighten-5"
                            class="mb-3"
                          >
                            <v-icon large color="purple">mdi-pulse</v-icon>
                          </v-avatar>
                          <div
                            class="d-flex flex-column align-start"
                            v-if="item.vital_signs?.length > 0"
                          >
                            <p class="text-h6 font-weight-bold">
                              Signos vitales registrados
                            </p>
                            <ul class="my-2">
                              <li
                                v-for="(element, index) in item.vital_signs"
                                :key="index"
                              >
                                <span class="font-weight-bold"
                                  >{{ element.VitalSign?.name }}:</span
                                >
                                <span class="font-weight-medium">
                                  {{ element.measurement
                                  }}{{ element.VitalSign?.measurement_unit }}
                                </span>
                              </li>
                            </ul>
                          </div>

                          <div v-else>
                            <p class="text-h6 font-weight-medium text-center">
                              No se registraron signos vitales.
                            </p>
                          </div>
                        </v-card>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-card
                          outlined
                          class="rounded-lg pa-5 d-flex flex-column align-center"
                        >
                          <v-avatar
                            size="80"
                            color="deep-purple lighten-5"
                            class="mb-3"
                          >
                            <v-icon large color="deep-purple"
                              >mdi-hospital-box-outline</v-icon
                            >
                          </v-avatar>
                          <div
                            class="d-flex flex-column align-start"
                            v-if="item.illness?.length > 0"
                          >
                            <p class="text-h6 font-weight-bold">
                              Padecimiento registrado
                            </p>
                            <ul class="my-2">
                              <li
                                v-for="(element, index) in item.illness"
                                :key="index"
                              >
                                <span class="font-weight-bold">{{
                                  element.disease
                                }}</span>
                              </li>
                            </ul>
                          </div>

                          <div v-else>
                            <p class="text-h6 font-weight-medium text-center">
                              No se registró padecimiento.
                            </p>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-row>
                </td>
              </template>

              <!-- Download report button -->
              <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center align-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :loading="item.loading"
                        :disabled="item.loading"
                        icon
                        @click="
                          getStudentVisitDetailedReport(
                            item.visit_id,
                            item.visit_date,
                            item.code
                          )
                        "
                        v-bind="attrs"
                        v-on="on"
                        color="primary lighten-5"
                      >
                        <v-icon
                          color="primary
"
                          >mdi-file</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Generar reporte de visíta</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import IconCard from "@/components/elements/dashboard/IconCard.vue";
import Swal from "sweetalert2";
import codeRepository from "../../../repositories/codeRepository";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "NursingVisitsDetailModal",
  mounted() {
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Panel disciplinario");
  },
  components: {
    IconCard,
  },
  props: {
    nursingVisits: {
      type: Array,
    },
    selectedStage: {
      type: Object,
    },
    dateRange: {
      type: Object,
    },
    colorTheme: {
      type: String,
      default: "primary",
    },
  },
  data() {
    return {
      dialogActiveState: false,

      deleteStatus: false,
      updateStatus: false,
      expandedStudents: [],
      studentVisitsHeaders: [
        // {
        //   text: "Estudiante",
        //   align: "start",
        //   value: "full_name",
        // },

        { text: "Fecha de visita", value: "visit_date" },
        // {
        //   text: "Generar reporte",
        //   align: "center",
        //   value: "actions",
        //   sortable: false,
        // },
        { text: "Más detalles", value: "data-table-expand", sortable: false },
      ],
    };
  },
  methods: {
    toggleModal() {
      this.dialogActiveState = !this.dialogActiveState;
    },

    showObservation(value) {
      return value == null ? "No se ha adjuntado ninguna observación." : value;
    },
    deleteCode(item) {
      Swal.fire({
        title: "Eliminación del código",
        text: "¿Realmente desea eliminar el código?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteStatus = true;
          codeRepository
            .deleteAssignedCode(item.studentAssignedCode.id)
            .then((response) => {
              // Delete code locally
              if (
                item.studentAssignedCode.AssignedCodeDetail.CodePlace.name ===
                "Aula"
              ) {
                const index = this.assignedCodes.aula.indexOf(item);
                this.$emit("removeCodes", { place: "aula", index: index });
              }
              if (
                item.studentAssignedCode.AssignedCodeDetail.CodePlace.name ===
                "Ex-aula"
              ) {
                const index = this.assignedCodes.ex_aula.indexOf(item);
                this.$emit("removeCodes", { place: "ex_aula", index: index });
              }
              this.fireToast({
                icon: "success",
                title: "El código se ha eliminado exitosamente",
              });
            })
            .catch((e) => {
              this.fireToast({
                title: "Ocurrió un error la eliminar el código",
                icon: "error",
              });
            })
            .finally(() => {
              this.deleteStatus = false;
              this.dialogActiveState = true;
            });
        }
      });
    },
    openCodeDetailModal(value) {
      this.$emit("openUpdateModal", value);
    },

    getStudentVisitDetailedReport(visitID, date, studentCode) {
      // Find the corresponding item in the formattedStudentsClinicVisits array
      const item = this.formattedStudentsClinicVisits.find(
        (item) => item.visit_id === visitID
      );

      if (item) {
        // Set loading state to true for the corresponding row
        item.loading = true;
        axios({
          url: `${this.DP_URL}/clinic-visit/report/id/${visitID}`,
          method: "GET",
          responseType: "blob",
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `reporte visita unidad de primeros auxilios ${studentCode} - ${this.fullFormattedDate(
                date
              )}.pdf`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch((e) => {
            this.fireToast({
              title: "Ocurrió un error al descargar el reporte",
            });
          })
          .finally(() => {
            item.loading = false;
          });
      }
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),

    formattedStudentsClinicVisits() {
      return this.nursingVisits.map((item) => ({
        visit_id: item.id,
        visit_date: item.visit_date_time,
        full_name: `${item.patient.first_name} ${item.patient.last_name}`,
        code: item.patient.code,
        grade: `${item.patient.grade} ${item.patient.technical_group}`,
        medication: item.medication,
        illness: item.illness,
        vital_signs: item.vital_signs,
        diagnostic: item.diagnostic,
        loading: false,
      }));
    },

    DP_URL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.DP_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DP_DEV_API_URL;
      } else {
        baseURL = process.env.DP_LOCAL_API_URL;
      }
      return baseURL;
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
  watch: {
    dialogActiveState(newVal, oldValue) {
      if (!newVal) {
        this.expandedStudents = [];
      }
    },
  },
};
</script>

<style>
.center-icon {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 24px; /* Adjust width as needed */
  height: 24px; /* Adjust height as needed */
}
</style>
