<template>
  <v-col lg="4" md="6" cols="12">
    <v-card>
      <v-card-title
        class="d-flex flex-row justify-space-between py-2 px-5 align-center"
      >
        <v-row>
          <v-col cols="2" class="d-flex align-center justify-center p-0">
            <v-avatar
              size="45"
              color="orange lighten-3"
              v-if="studentClass.attendance_status_id == 5"
            >
              <v-icon color="orange darken-4"
                >mdi-office-building-outline</v-icon
              >
            </v-avatar>
            <v-avatar
              size="45"
              color="red lighten-3"
              v-if="studentClass.attendance_status_id == 2"
            >
              <v-icon color="red darken-4">mdi-account-arrow-left</v-icon>
            </v-avatar>
            <v-avatar
              size="45"
              color="amber lighten-3"
              v-if="studentClass.attendance_status_id == 3"
            >
              <v-icon color="amber darken-4">mdi-clock-alert-outline</v-icon>
            </v-avatar>
          </v-col>
          <v-col cols="8" class="py-5 d-flex flex-column pr-0 pl-1">
            <span class="font-weight-medium text-h6 my-0 py-0">{{
              studentClass.subject
            }}</span>
            <span class="text-subtitle-1 black--text">{{
              textAttendaceStatus(studentClass.attendance_status_id)
            }}</span>
          </v-col>
          <v-col cols="2" class="py-3 my-0">
            <v-checkbox
              v-model="selected"
              :color="`${colorByAttendanceStatus(
                studentClass.attendance_status_id
              )} darken-4`"
              @change="methodGetStudentClassId(studentClass.student_class_id)"
              :disabled="!existInArray('Eliminar', currentPageActions)"
            ></v-checkbox>
          </v-col>
        </v-row>
        <div></div>
      </v-card-title>
      <v-card-text class="text-h6 pt-3">{{
        `Horario: ${formatTime(studentClass.start_time)} - ${formatTime(
          studentClass.end_time
        )}`
      }}</v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from "dayjs";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { mapGetters } from "vuex";

dayjs.extend(customParseFormat)

export default {
  name: "StudentClassItem",
  data: () => ({
    selected: false,
  }),
  props: {
    studentClass: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      default: "blue",
    },
    methodGetStudentClassId: {
      type: Function,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatTime(value) {
      return dayjs(value, "HH:mm:ss").format("hh:mm a");
    },
    colorByAttendanceStatus(attendanceStatus) {
      if (attendanceStatus == 2) {
        return "deep-orange";
      } else if (attendanceStatus == 3) {
        return "orange";
      } else if (attendanceStatus == 5) {
        return "amber";
      }
    },
    textAttendaceStatus(attendanceStatus) {
      if (attendanceStatus == 2) {
        return "Inasistencia";
      } else if (attendanceStatus == 3) {
        return "Llegada tarde a clase";
      } else if (attendanceStatus == 5) {
        return "Llegada tarde a la institución";
      }
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
  },
  watch: {
    isSelected(newValue, oldValue) {
      this.selected = newValue;
    }
  },
  mounted() {
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Panel disciplinario");
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
}
</script>