<template>
  <v-dialog
    v-model="showModal"
    transition="dialog-bottom-transition"
    width="600"
  >
    <v-card>
      <v-card-title class="py-7">Modificación de observación</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <p class="text-h6 font-weight-bold text-center mb-0">
              Observación seleccionada
            </p>

            <v-row class="mt-2">
              <v-col cols="12" class="pb-0">
                <form-group
                  name="Observación"
                  :validator="$v.currentObservation.observation"
                >
                  <v-textarea
                    counter
                    filled
                    clearable
                    rounded
                    auto-grow
                    rows="2"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Observación (opcional)"
                    placeholder="Ej.: El estudiante..."
                    v-model.trim="currentObservation.observation"
                    @paste="
                      handlePaste($event, 'currentObservation.observation')
                    "
                    @keyup.enter="handleKeyUpMotive()"
                  ></v-textarea>
                </form-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  @click="submitUpdatedObservation()"
                  :loading="isSavingObservation"
                  :disabled="isSavingObservation"
                  class="mb-2"
                >
                  <span class="font-weight-medium">Actualizar observación</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// Repositories
import observationRepository from "@/repositories/observationRepository";
import { maxLength, required } from "vuelidate/lib/validators";

export default {
  name: "UpdateObservationDetailsModal",
  props: {
    currentObservation: {
      type: Object,
    },
  },

  data() {
    return {
      showModal: false,
      observation: null,
      isSavingObservation: false,
    };
  },

  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },

    submitUpdatedObservation() {
      this.$v.currentObservation.$touch();

      if (!this.$v.currentObservation.$invalid) {
        this.isSavingObservation = true;

        let payload = {
          observation: this.currentObservation.observation,
          student_id: this.currentObservation.student_id,
          user_id: this.currentObservation.user_data.id_user,
          date: new Date().toISOString().slice(0, 10),
        };

        observationRepository
          .updateObservation(this.currentObservation.id, payload)
          .then(() => {
            //Updating observation locally
            this.$emit("updateObservation", this.currentObservation);

            //Show success alert
            this.sweetAlertResponse({
              status: true,
              message: `La observación se ha actualizado con éxito.`,
            });

            this.showModal = false;
          })
          .catch((error) => {
            this.sweetAlertResponse({
              status: false,
              message: `No se pudo regitrar la observación. Por favor, intente más tarde o contacte a  soporte técnico.`,
            });
            console.log(error);
          })
          .finally(() => {
            this.isSavingObservation = false;
          });

        return;
      }
    },

    // Text area handler methods
    handlePaste(event) {
      event.preventDefault();
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      paste = paste.replace(/ {3,}/g, "  ");
      this.currentObservation.observation =
        this.currentObservation.observation + paste;
    },

    handleKeyUpMotive() {
      this.$v.currentObservation.$touch();
      if (!this.$v.currentObservation.$invalid) {
        //la observación es válida, entonces:
      }
    },
  },
  validations: {
    currentObservation: {
      observation: {
        required,
      },
    },
  },
  watch: {
    currentObservation(newValue) {
      if (newValue) {
        // Initialize your component state based on the new value
        this.observation = newValue.observation;
      }
    },
  },
};
</script>