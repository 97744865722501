<template>
  <v-dialog
    v-model="showModal"
    transition="dialog-bottom-transition"
    width="550"
  >
    <v-card>
      <!--     <v-container class="py-7" :class="`${colorTheme}`"></v-container>
    <v-container class="px-7">
        <p class="text-h4 font-weight-bold mb-1 d-flex align-center">
          Justificado
          <v-icon :color="`${colorTheme}`" class="ml-2"
            >mdi-check-circle-outline</v-icon
          >
        </p>
        <p class="pt-0 mt-0">
          {{ formatDate(headerData?.created_at) }}
        </p>
        <div class="mt-5">
          <p>
            {{ message }}
            <span class="font-weight-bold">
              {{
                `${headerData?.user_data?.first_name} ${headerData?.user_data?.last_name}`
              }}
            </span>
            a las
            <span class="font-weight-bold">
              {{ `${formatTime(headerData?.created_at)}.` }}
            </span>
          </p>
        </div>
        <div class="d-flex justify-end">
          <v-btn
            outlined
            class="rounded-xl font-weight-bold"
            @click="
              excecuteAction(headerData?.student_request_type_id, headerData)
            "
            :color="colorTheme"
            >Ver más detalles</v-btn
          >
        </div>
      </v-container> -->
      <v-card-title class="py-7">Modificación del código asignado</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <p class="text-h6 font-weight-bold text-center mb-0">
              Código seleccionado
            </p>
            <v-list-item-group v-model="selectedCodeList">
              <v-list class="v-list">
                <v-list-item @click="showMdlSelectCode()" class="rounded-lg">
                  <v-list-item-content class="pb-0">
                    <v-list-item-subtitle class="mb-1">
                      <v-chip
                        :color="`${
                          setCodeTypeTheme(selectedCodeFromModal.CodeType?.name)
                            .color
                        } lighten-4 font-weight-medium`"
                        class="mt-1"
                      >
                        <p
                          :class="`font-weight-medium ${
                            setCodeTypeTheme(
                              selectedCodeFromModal.CodeType?.name
                            ).color
                          }--text mb-0`"
                        >
                          Código
                          <span
                            :class="`text-lowercase font-weight-bold ${
                              setCodeTypeTheme(
                                selectedCodeFromModal.CodeType?.name
                              ).color
                            }--text`"
                            >{{ selectedCodeFromModal.CodeType?.name }}</span
                          >
                        </p>
                      </v-chip>
                    </v-list-item-subtitle>

                    <p class="font-weight-bold my-1 ml-3 pr-2">
                      {{ selectedCodeFromModal.name }}
                    </p>

                    <p class="font-weight-medium text--secondary ml-3 pr-3">
                      {{ selectedCodeFromModal.description }}
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list-item-group>
            <v-row class="mt-2">
              <v-col cols="12" class="pb-0">
                <form-group name="Observación" :validator="$v.observation">
                  <v-textarea
                    counter
                    filled
                    clearable
                    rounded
                    auto-grow
                    rows="2"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Observación (opcional)"
                    placeholder="Ej.: El estudiante..."
                    v-model.trim="observation"
                    @blur="$v.observation.$touch()"
                    @paste="handlePaste($event)"
                    @keyup.enter="handleKeyUpMotive()"
                  ></v-textarea>
                </form-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn
                  :class="`${
                    setCodeTypeTheme(selectedCodeFromModal.CodeType?.name).color
                  } lighten-4 mb-2`"
                  @click="submitUpdateCode()"
                >
                  <v-icon
                    left
                    :color="`${
                      setCodeTypeTheme(selectedCodeFromModal.CodeType?.name)
                        .color
                    }`"
                    >mdi-paperclip-plus</v-icon
                  >
                  <span
                    :class="`${
                      setCodeTypeTheme(selectedCodeFromModal.CodeType?.name)
                        .color
                    }--text font-weight-bold d-none d-sm-inline`"
                    >Actualizar código</span
                  >
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <SelectCodeModal
      ref="mdlSelectCode"
      colorTheme="primary"
      :codes="codes.data"
      :codeTypes="codeTypes"
      @codeSelected="setSelectedCodeDetails($event)"
    ></SelectCodeModal>
  </v-dialog>
</template>

<script>
// Components
import SelectCodeModal from "@/components/modals/codes/SelectCodeModal.vue";

// Repositories
import codeRepository from "@/repositories/codeRepository";
import codeTypeRepository from "@/repositories/codeTypeRepository";

import { minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "UpdateCodeDetailsModal",
  props: {
    currentCodeObservation: {
      type: String,
      default: "Sin observación asignada",
    },
    currentCode: {
      type: Object,
    },
  },
  components: { SelectCodeModal },
  data: () => ({
    showModal: false,
    observation: null,
    selectedCodeFromModal: {},
    selectedCodeList: [],
    codes: {
      type: Object,
      default: () => {},
    },
    codeTypes: {
      type: Object,
      default: () => {},
    },
    loadingMode: false,
    loadingUpdate: false,
  }),
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    setCodeTypeTheme(codeTypeName) {
      let codeTypeSchemas = {
        Positivo: {
          color: "green",
          icon: "star",
        },
        Leve: {
          color: "orange",
          icon: "star-minus",
        },
        Grave: {
          color: "red",
          icon: "star-minus",
        },
        "Muy grave": {
          color: "purple",
          icon: "star-minus",
        },
      };

      let defaultSchema = {
        color: "grey",
        icon: "star-minus",
      };

      if (codeTypeSchemas.hasOwnProperty(codeTypeName)) {
        return codeTypeSchemas[codeTypeName];
      } else {
        return defaultSchema;
      }
    },
    showMdlSelectCode() {
      this.$refs.mdlSelectCode.toggleModal();
    },

    setSelectedCodeDetails(code) {
      this.selectedCodeFromModal = code;
      this.selectedCodeList = null;
    },

    assignedSelectedCodeToSelectedStudents() {
      //validate all data before creating the body of the request

      let observation = this.observation == "" ? null : this.observation;

      if (!this.$v.observation.$error && this.validateRegex) {
        let selectedStudentsUUIDs = [];

        for (
          let index = 0;
          index < this.studentsSelectedForCodeAssigning.length;
          index++
        ) {
          const student = this.studentsSelectedForCodeAssigning[index];
          selectedStudentsUUIDs.push(student.uuid);
        }

        const codeAboutToBeAssignRequestBody = {
          selected_students: selectedStudentsUUIDs,
          student_class_id: null,
          code_id: this.selectedCodeFromModal.id,
          observation: observation,
          id_user: this.currentUserPersonalInfo.id_user,
          code_place_id: this.OUTSIDE_CLASSROOM_CODEPLACE_UUID,
        };

        //making request
        this.$emit("assignCodesToStudent", codeAboutToBeAssignRequestBody);

        this.resetCardState();

        return;
      }

      this.fireToast({
        icon: "error",
        title: `Hay datos invalidos, por favor intente de nuevo`,
      });
    },
    // Text area handler methods
    handlePaste(event) {
      event.preventDefault();
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      paste = paste.replace(/ {3,}/g, "  ");
      this.observation = this.observation + paste;
    },

    handleKeyUpMotive() {
      this.$v.observation.$touch();
      if (!this.$v.observation.$invalid) {
        //la observación es válida, entonces:
      }
    },
    submitUpdateCode() {
      const payload = {
        observation: this.observation == "" ? null : this.observation,
        code_id: this.selectedCodeFromModal.id,
      };
      this.loadingUpdate = true;
      codeRepository
        .updateDetailsCode(this.currentCode.id, payload)
        .then((data) => {
          this.$emit("updateCode", {
            place: this.currentCode.CodePlace.name,
            observation: this.observation,
            code: this.selectedCodeFromModal,
            current: this.currentCode,
          });
          this.fireToast({
            title: "El código se ha actualizado con éxito",
            icon: "success",
          });
          this.showModal = false;
        })
        .catch((e) => {
          this.fireToast({
            title: "Ocurrió un error al actualizar el código",
            icon: "error",
          });
        })
        .finally(() => {
          this.loadingUpdate = false;
        });
    },

    // Load initial data
    loadCodes() {
      this.loadingMode = true;
      codeRepository
        .getAllCodes()
        .then(({ data }) => {
          this.codes = data;
        })
        .catch((e) => {
          this.fireToast({
            title: "Ocurrió un error al cargar los códigos.",
            icon: "error",
          });
        })
        .finally(() => {
          if (Object.keys(this.codeTypes).length !== 0) {
            this.loadingMode = false;
          }
        });
    },
    loadCodeTypes() {
      this.loadingMode = true;
      codeTypeRepository
        .getAllCodeTypes()
        .then(({ data }) => {
          this.codeTypes = data;
        })
        .catch((e) => {
          this.fireToast({
            title: "Ocurrió un error al cargar los tipos de códigos",
            icon: "error",
          });
        })
        .finally(() => {
          if (Object.keys(this.codes).length !== 0) {
            this.loadingMode = false;
          }
        });
    },
  },
  computed: {
    aCodeHasBeenSelectedFromModal() {
      return Object.keys(this.selectedCodeFromModal).length !== 0;
    },
  },
  mounted() {
    this.loadCodes();
    this.loadCodeTypes();
  },
  validations: {
    observation: {
      minLength: minLength(10),
      maxLength: maxLength(240),
    },
  },
  watch: {
    currentCodeObservation(newValue, _oldValue) {
      this.observation = newValue;
    },
    currentCode(newValue, _oldValue) {
      this.selectedCodeFromModal = newValue.Code;
    },
  },
};
</script>