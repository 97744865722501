<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark :color="`${colorTheme} lighten-1`">
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="closeModal()"> Cerrar </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container>
        <p class="text-h4 text-center font-weight-medium mb-0 mt-4">
          {{ titleLabel }}
        </p>
      </v-container>
      <v-scroll-x-transition>
        <v-container
          v-if="!isLoading"
          :leave-absolute="true"
          :hide-on-leave="true"
        >
          <v-row
            justify="center"
            class="mt-6"
            v-if="studentClassInformation.length !== 0"
          >
            <v-col cols="12" class="px-0 px-sm-5">
              <v-data-table
                :headers="headers"
                :items="studentClassInformation"
                :single-expand="true"
                :expanded.sync="expanded"
                item-key="justification_permission_header_id"
                :sort-by.sync="sortBy"
                :sort.sync="sortBy"
                show-expand
              >
                <!-- Empieza:: datos para estudiantes y panel disciplinario -->
                <template v-slot:[`item.from_date`]="{ item }">
                  {{ capitalizeFirstLetter(formatDate(item.from_date)) }}
                </template>
                <template v-slot:[`item.to_date`]="{ item }">
                  {{ capitalizeFirstLetter(formatDate(item.to_date)) }}
                </template>
                <template v-slot:[`item.type`]="{ item }">
                  <span>{{ `${faultTypeText} ${typeText(item.type)}` }}</span>
                </template>
                <!-- Finaliza:: Datos para estudiantes y panel displinario -->

                <!-- Empieza:: Datos para coordinaciones -->
                <template v-slot:[`item.student_name`]="{ item }">
                  {{ `${item.student.first_name} ${item.student.last_name}` }}
                </template>
                <template v-slot:[`item.code`]="{ item }">
                  {{ item.student.code }}
                </template>
                <template v-slot:[`item.user`]="{ item }">
                  {{ `${item.user.first_name} ${item.user.last_name}` }}
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  {{ formatDate(item.created_at) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <template v-if="existInArray('Eliminar', currentPageActions)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-5"
                          @click="
                            selectItem(item);
                            showMdlDelete();
                          "
                          color="red"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </template>
                </template>
                <!-- Finaliza:: Datos para coordinaciones -->
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-container fluid>
                      <v-row class="d-flex my-3">
                        <!-- Información general del permiso o justificación -->
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="font-weight-bold d-flex d-md-block justify-center"
                              >Información del permiso</v-card-title
                            >
                            <v-card-text>
                              <!-- Inicia:: Contenido del header para el panel disciplinario -->
                              <v-row>
                                <v-col
                                  lg="7"
                                  md="6"
                                  cols="12"
                                  class="d-flex d-md-block flex-column align-center py-0 py-md-2"
                                >
                                  <p class="text-subtitle-1 font-weight-medium">
                                    Motivo:
                                    <span class="text-subtitle-1">{{
                                      item.motive || "Sin especificar"
                                    }}</span>
                                  </p>
                                  <p class="text-subtitle-1 font-weight-medium">
                                    Archivo:
                                    <v-btn
                                      v-if="item.file"
                                      class="ml-2 font-weight-medium"
                                      :class="`${colorTheme} lighten-2`"
                                      @click="downloadFile(item.file)"
                                      >Descargar comprobante</v-btn
                                    >

                                    <span class="text-subtitle-1" v-else
                                      >No se adjuntó ninguna constancia</span
                                    >
                                  </p>
                                  <p
                                    class="text-subtitle-1 font-weight-medium d-flex mb-0"
                                  >
                                    Duración:
                                    <span class="text-subtitle-1">
                                      <div class="ml-2">
                                        <p class="d-none d-md-block">
                                          Desde
                                          <span class="font-weight-bold">{{
                                            formatShortDate(item.from_date)
                                          }}</span>
                                          hasta
                                          <span class="font-weight-bold">{{
                                            formatShortDate(item.to_date)
                                          }}</span>
                                        </p>
                                        <p class="d-block d-md-none">
                                          {{
                                            `${item.from_date} -  ${item.to_date}`
                                          }}
                                        </p>
                                      </div>
                                    </span>
                                  </p>
                                </v-col>
                                <v-col
                                  lg="5"
                                  md="6"
                                  cols="12"
                                  class="d-flex d-md-block flex-column align-center py-0 py-md-2"
                                >
                                  <p class="text-subtitle-1 font-weight-medium">
                                    Asignado por:
                                    <span class="text-subtitle-1">{{
                                      `${item.user.first_name} ${item.user.last_name}`
                                    }}</span>
                                  </p>
                                  <p class="text-subtitle-1 font-weight-medium">
                                    Creado:
                                    <span class="text-subtitle-1">{{
                                      capitalizeFirstLetter(
                                        formatCompleteDate(item.created_at)
                                      ) || "Sin especificar"
                                    }}</span>
                                  </p>
                                </v-col>
                              </v-row>
                              <!-- Finaliza:: Contenido del header para el panel disciplinario -->
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <!-- Contenido del permiso -->
                        <v-col cols="12" class="mb-0">
                          <h6
                            class="font-weight-bold"
                            v-if="item.type == 'Permiso'"
                          >
                            Clases con permiso
                          </h6>
                          <h6 class="font-weight-bold" v-else>
                            Clases justificadas
                          </h6>
                          <v-expansion-panels focusable class="mt-3">
                            <v-expansion-panel
                              v-for="studentByDate in item.classes"
                              :key="studentByDate.index"
                            >
                              <!-- Encabezado -->
                              <v-expansion-panel-header
                                class="text-subtitle-1 font-weight-medium d-flex justify-start"
                              >
                                <v-row>
                                  <v-col cols="1" class="py-1">
                                    <v-chip
                                      :color="`${colorTheme} lighten-3`"
                                      class="d-flex justify-center mx-2 mx-sm-0 px-0"
                                    >
                                      <v-icon :color="`${colorTheme} darken-3`"
                                        >mdi-calendar-month</v-icon
                                      >
                                    </v-chip>
                                  </v-col>
                                  <v-col
                                    cols="11"
                                    class="py-1 d-flex align-center"
                                  >
                                    <span class="font-weight-bold">{{
                                      capitalizeFirstLetter(
                                        formatDate(studentByDate.date)
                                      )
                                    }}</span>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row class="mt-5 mb-0">
                                  <v-col cols="12">
                                    <v-card class="mx-auto" tile elevation="0">
                                      <v-list dense>
                                        <v-list-item-group color="primary">
                                          <v-list-item
                                            v-for="studentClass in studentByDate.schedules"
                                            :key="studentClass.index"
                                            class="elevation-1"
                                          >
                                            <v-list-item-icon>
                                              <v-icon
                                                >mdi-office-building-outline</v-icon
                                              >
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                {{ studentClass.subject }} -
                                                {{
                                                  `Horario: ${formatTime(
                                                    studentClass.start_time
                                                  )} - ${formatTime(
                                                    studentClass.end_time
                                                  )}`
                                                }}
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                        </v-list-item-group>
                                      </v-list>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="mt-10" v-else>
            <v-col class="mx-auto d-flex flex-column" cols="12" sm="10">
              <div class="mx-auto mb-4 pa-8 rounded-circle green lighten-4">
                <v-icon large color="success">mdi-check-circle-outline</v-icon>
              </div>
              <div>
                <p class="text-center font-weight-bold text-h6 mb-1">
                  Sin asignaciones
                </p>
                <p class="text-center font-weight-medium text-body-1">
                  {{ emptyText }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-scroll-x-transition>
    </v-card>
    <DeleteBaseModal ref="mdlDelete" @delete="deletePermission">
      <template v-slot:title>¿Desea eliminar este permiso?</template>
      <template v-slot:subject>
        <span class="font-weight-bolder"> el permiso</span>
      </template>
    </DeleteBaseModal>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import DeleteBaseModal from "@/components/modals/DeleteBaseModal.vue";
import studentJustificationsRepository from "@/repositories/studentJustificationsRepository";
import dayjs from "dayjs";

export default {
  name: "permisionInfoModal",
  props: {
    titleLabel: {
      type: String,
    },
    colorTheme: {
      type: String,
      default: "blue",
    },
    studentClassInformation: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    faultType: {
      type: Number,
      required: true,
    },
  },
  components: {
    DeleteBaseModal,
  },
  data: () => ({
    showDialog: false,
    expanded: [],
    sortBy: "created_at",
    tableHeaders: [
      {
        text: "Inicia",
        align: "start",
        sortable: true,
        value: "from_date",
      },
      {
        text: "Finaliza",
        align: "start",
        sortable: true,
        value: "to_date",
      },
      {
        text: "Tipo",
        align: "start",
        sortable: false,
        value: "type",
      },
      {
        text: "Detalle",
        groupable: false,
        value: "data-table-expand",
      },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    selectedPermission: {},
  }),
  methods: {
    formatTime(value) {
      return dayjs(value, "HH:mm:ss").format("hh:mm a");
    },
    toggleModal() {
      this.showDialog = !this.showDialog;
    },
    closeModal() {
      this.showDialog = false;
      this.sortBy = "created_at";
      this.expanded = [];
    },
    formatDate(value) {
      return dayjs(value).format("dddd DD [de] MMMM [de] YYYY");
    },
    formatShortDate(value) {
      return dayjs(value).format("DD [de] MMMM [de] YYYY");
    },
    formatCompleteDate(value) {
      return this.fullFormattedDate(
        value,
        "dddd DD [de] MMMM [de] YYYY [a las] hh:mm a"
      );
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    typeText(type) {
      if (type == "ITR") return type;
      return "";
    },
    async downloadFile(url) {
      try {
        // Crear un elemento <a> de manera dinámica
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "filename.jpeg"); // Puedes cambiar el nombre del archivo según sea necesario

        // Añadir el <a> al DOM y hacer clic en él
        document.body.appendChild(link);
        link.click();

        // Eliminar el <a> del DOM
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading file", error);
      }
    },
    selectItem(obj) {
      this.selectedPermission = { ...obj };
    },
    showMdlDelete() {
      this.$refs.mdlDelete.toggleModal();
    },
    deletePermission() {
      studentJustificationsRepository
        .deletePermission(
          this.selectedPermission.justification_permission_header_id,
          this.selectedPermission.student_id
        )
        .then(({ data }) => {
          this.fireToast({
            title: data.message,
            icon: "success",
          });
          this.$emit("reloadData");
          this.closeModal();
        })
        .catch(() => {
          this.fireToast({
            title: "Ocurrió un error al remover el permiso",
            icon: "error",
          });
        });
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
    faultTypeText() {
      if (this.faultType == 1) {
        return "Permiso";
      }
    },
    emptyText() {
      return "Sin permisos encontrados";
    },
    headers() {
      return this.tableHeaders;
    },
  },
  watch: {
    showDialog(newVal, oldValue) {
      if (!newVal) {
        this.expanded = [];
      }
    },
  },
};
</script>
