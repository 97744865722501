<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark :color="`${colorTheme} lighten-1`">
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="closeModal()"> Cerrar </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container>
        <p class="text-h4 text-center font-weight-medium mb-0 mt-4">
          {{ titleLabel }}
        </p>
      </v-container>
      <!-- Buscador por fechas-->
      <v-row class="px-0 mt-5 mx-0" justify="center" v-if="forCoordinations">
        <v-col cols="12" md="4">
          <v-menu
            v-model="showStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="isLoading"
                v-model="searchData.startDate"
                label="Desde"
                prepend-inner-icon="mdi-calendar-start"
                readonly
                v-bind="attrs"
                v-on="on"
                rounded
                filled
              ></v-text-field>
            </template>
            <v-date-picker
              :min="`${new Date().getFullYear()}-01-01`"
              :allowed-dates="allowDates"
              v-model="searchData.startDate"
              @input="(showStartDate = false), (searchData.endDate = undefined)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="4">
          <v-menu
            v-model="showEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="isLoading"
                v-model="searchData.endDate"
                label="Hasta"
                prepend-inner-icon="mdi-calendar-start"
                readonly
                v-bind="attrs"
                v-on="on"
                rounded
                filled
              ></v-text-field>
            </template>
            <v-date-picker
              :max="`${new Date().getFullYear()}-12-31`"
              :allowed-dates="allowDates"
              v-model="searchData.endDate"
              @input="(showEndDate = false), searchForInformation()"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <!-- Buscador de contenido -->
      <v-scroll-x-transition :leave-absolute="true" :hide-on-leave="true">
        <v-container v-if="isLoading" class="fill-height">
          <v-row>
            <v-col cols="12">
              <v-card
                height="250"
                class="transparent elevation-0 d-flex flex-column justify-center align-center"
              >
                <v-progress-circular
                  class="mb-5"
                  indeterminate
                  rounded
                  :color="`${colorTheme} darken-2`"
                  size="75"
                  width="5"
                ></v-progress-circular>
                <p class="text-center font-weight-medium text-body-1">
                  Cargando contenido...
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-scroll-x-transition>
      <v-scroll-x-transition>
        <v-container
          v-if="!isLoading"
          :leave-absolute="true"
          :hide-on-leave="true"
        >
          <v-row
            justify="center"
            class="mt-6"
            v-if="studentClassInformation.length !== 0"
          >
            <v-col cols="12" v-if="sortBy == null">
              <span
                >Actualmente, los datos no se encuentran organizados para
                mostrar la información adicional. Si deseas reordenarlo, haz
                click aquí:</span
              >
              <v-btn
                class="ml-2"
                :color="`${colorTheme} lighten-3`"
                @click="reorderData()"
                >Reordenar</v-btn
              >
            </v-col>
            <v-col cols="12" class="px-0 px-sm-5">
              <v-data-table
                :headers="headers"
                :items="studentClassInformation"
                :single-expand="true"
                :expanded.sync="expanded"
                item-key="justification_permission_header_id"
                :sort-by.sync="sortBy"
                :sort.sync="sortBy"
                show-expand
              >
                <!-- Empieza:: datos para estudiantes y panel disciplinario -->
                <template v-slot:[`item.from_date`]="{ item }">
                  {{ capitalizeFirstLetter(formatDate(item.from_date)) }}
                </template>
                <template v-slot:[`item.to_date`]="{ item }">
                  {{ capitalizeFirstLetter(formatDate(item.to_date)) }}
                </template>
                <template v-slot:[`item.type`]="{ item }">
                  <span>{{ `${faultTypeText} ${typeText(item.type)}` }}</span>
                </template>
                <!-- Finaliza:: Datos para estudiantes y panel displinario -->

                <!-- Empieza:: Datos para coordinaciones -->
                <template v-slot:[`item.student_name`]="{ item }">
                  {{ `${item.student.first_name} ${item.student.last_name}` }}
                </template>
                <template v-slot:[`item.code`]="{ item }">
                  {{ item.student.code }}
                </template>
                <template v-slot:[`item.user`]="{ item }">
                  {{ `${item.user.first_name} ${item.user.last_name}` }}
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  {{ formatDate(item.created_at) }}
                </template>
                <!-- Finaliza:: Datos para coordinaciones -->
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-container fluid>
                      <v-row class="d-flex my-3">
                        <!-- Información general del permiso o justificación -->
                        <v-col cols="12">
                          <v-card>
                            <v-card-title
                              class="font-weight-bold d-flex d-md-block justify-center"
                              v-if="faultType == 1"
                              >Información del permiso</v-card-title
                            >
                            <v-card-title class="font-weight-bold d-flex d-md-block justify-center" v-else
                              >Información de la justificación</v-card-title
                            >
                            <v-card-text>
                              <!-- Inicia:: Contenido para coordinaciones -->
                              <v-row v-if="forCoordinations">
                                <!-- Datos del estudiante -->
                                <v-col lg="2" md="2" sm="12" class="d-flex d-md-block flex-column align-center px-5">
                                  <v-avatar size="150" class="mb-2">
                                    <v-img :src="item.student.photo"/>
                                  </v-avatar>
                                </v-col>
                                <v-col cols="12" md="3" sm="12" class="d-flex d-md-block flex-column align-center">
                                  <span class="d-flex align-center text-subtitle-1 font-weight-medium mb-3">
                                    Nombre: 
                                    <p class="ml-1 py-0 my-0 font-weight-normal">{{ `${item.student.first_name} ${item.student.last_name}` }}</p>
                                  </span>
                                  <span class="d-flex align-center text-subtitle-1 font-weight-medium mb-3">
                                    Código: 
                                    <p class="ml-1 py-0 my-0 font-weight-normal">{{ item.student.code }}</p>
                                  </span>
                                  <span class="d-flex align-center text-subtitle-1 font-weight-medium mb-3">
                                    Grado: 
                                    <p class="ml-1 py-0 my-0 font-weight-normal">{{ `${item.student.grade} - ${item.student.section_group}` }}</p>
                                  </span>
                                  <span class="d-flex align-center text-subtitle-1 font-weight-medium mb-3">
                                    Especialidad: 
                                    <p v-if="item.student.grade_id >= 4" class="ml-1 py-0 my-0 font-weight-normal">{{ item.student.technical_group }}</p>
                                    <p v-else class="ml-1 py-0 my-0 font-weight-normal">Ninguna</p>
                                  </span>
                                </v-col>
                                <!-- Resto del contenido del header-->
                                <v-col lg="4" md="4" cols="12" class="py-0 py-md-2 d-flex d-md-block flex-column align-center">
                                  <p class="text-subtitle-1 font-weight-medium">
                                    Motivo:
                                    <span class="text-subtitle-1">{{
                                      item.motive || "Sin especificar"
                                    }}</span>
                                  </p>
                                  <p class="text-subtitle-1 font-weight-medium">
                                    Archivo:
                                    <v-btn
                                      v-if="item.file"
                                      class="ml-2 font-weight-medium"
                                      :class="`${colorTheme} lighten-2`"
                                      @click="downloadFile(item.file)"
                                      >Descargar comprobante</v-btn
                                    >

                                    <span class="text-subtitle-1" v-else
                                      >No se adjuntó ninguna constancia</span
                                    >
                                  </p>
                                  <p
                                    class="text-subtitle-1 font-weight-medium d-flex mb-0"
                                  >
                                    Duración:
                                    <span class="text-subtitle-1">
                                      <div
                                        class="ml-2"
                                        v-if="item.from_date && item.to_date"
                                      >
                                        <p class="d-none d-md-block">
                                          Desde
                                          <span class="font-weight-bold">{{
                                            formatShortDate(item.from_date)
                                          }}</span>
                                          hasta
                                          <span class="font-weight-bold">{{
                                            formatShortDate(item.to_date)
                                          }}</span>
                                        </p>
                                        <p class="d-sm-block d-md-none font-weight-bold">
                                          {{ `${item.from_date} - ${item.to_date}` }}
                                        </p>
                                      </div>
                                    </span>
                                  </p>
                                </v-col>
                                <v-col lg="3" md="4" cols="12" class="py-0 py-md-2 d-flex d-md-block flex-column align-center">
                                  <p class="text-subtitle-1 font-weight-medium">
                                    Asignado por:
                                    <span class="text-subtitle-1">{{
                                      `${item.user.first_name} ${item.user.last_name}`
                                    }}</span>
                                  </p>
                                  <p class="text-subtitle-1 font-weight-medium">
                                    Creado:
                                    <span class="text-subtitle-1 d-none d-md-block">{{
                                      capitalizeFirstLetter(
                                        formatCompleteDate(item.created_at)
                                      ) || "Sin especificar"
                                    }}</span>
                                    <span class="text-subtitle-1 d-sm-block d-md-none">{{
                                      formatShortDate(item.created_at) || "Sin especificar"
                                    }}</span>
                                  </p>
                                </v-col>
                              </v-row>
                              <!-- Inicia:: Contenido del header para el panel disciplinario -->
                              <v-row v-else>
                                <v-col lg="7" md="6" cols="12" class="d-flex d-md-block flex-column align-center py-0 py-md-2">
                                  <p class="text-subtitle-1 font-weight-medium">
                                    Motivo:
                                    <span class="text-subtitle-1">{{
                                      item.motive || "Sin especificar"
                                    }}</span>
                                  </p>
                                  <p class="text-subtitle-1 font-weight-medium">
                                    Archivo:
                                    <v-btn
                                      v-if="item.file"
                                      class="ml-2 font-weight-medium"
                                      :class="`${colorTheme} lighten-2`"
                                      @click="downloadFile(item.file)"
                                      >Descargar comprobante</v-btn
                                    >

                                    <span class="text-subtitle-1" v-else
                                      >No se adjuntó ninguna constancia</span
                                    >
                                  </p>
                                  <p
                                    class="text-subtitle-1 font-weight-medium d-flex mb-0"
                                  >
                                    Duración:
                                    <span class="text-subtitle-1">
                                      <div
                                        class="ml-2"
                                      >
                                        <p class="d-none d-md-block">
                                          Desde
                                          <span class="font-weight-bold">{{
                                            formatShortDate(item.from_date)
                                          }}</span>
                                          hasta
                                          <span class="font-weight-bold">{{
                                            formatShortDate(item.to_date)
                                          }}</span>
                                        </p>
                                        <p class="d-block d-md-none">
                                          {{ `${item.from_date} -  ${item.to_date}` }}
                                        </p>
                                      </div>
                                    </span>
                                  </p>
                                </v-col>
                                <v-col lg="5" md="6" cols="12" class="d-flex d-md-block flex-column align-center py-0 py-md-2">
                                  <p class="text-subtitle-1 font-weight-medium">
                                    Asignado por:
                                    <span class="text-subtitle-1">{{
                                      `${item.user.first_name} ${item.user.last_name}`
                                    }}</span>
                                  </p>
                                  <p class="text-subtitle-1 font-weight-medium">
                                    Creado:
                                    <span class="text-subtitle-1">{{
                                      capitalizeFirstLetter(
                                        formatCompleteDate(item.created_at)
                                      ) || "Sin especificar"
                                    }}</span>
                                  </p>
                                </v-col>
                              </v-row>
                              <!-- Finaliza:: Contenido del header para el panel disciplinario -->
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <!-- Contenido del permiso -->
                        <v-col cols="12" class="mb-0">
                          <h6
                            class="font-weight-bold"
                            v-if="item.type == 'Permiso'"
                          >
                            Clases con permiso
                          </h6>
                          <h6 class="font-weight-bold" v-else>
                            Clases justificadas
                          </h6>
                          <v-expansion-panels focusable class="mt-3">
                            <v-expansion-panel
                              v-for="studentByDate in item.classes"
                              :key="studentByDate.index"
                            >
                            <!-- Encabezado -->
                              <v-expansion-panel-header
                                class="text-subtitle-1 font-weight-medium d-flex justify-start"
                              >
                                <v-row>
                                  <v-col cols="1" class="py-1">
                                    <v-chip
                                      :color="`${colorTheme} lighten-3`"
                                      class="d-flex justify-center mx-2 mx-sm-0 px-0"
                                    >
                                      <v-icon :color="`${colorTheme} darken-3`"
                                        >mdi-calendar-month</v-icon
                                      >
                                    </v-chip>
                                  </v-col>
                                  <v-col
                                    cols="11"
                                    class="py-1 d-flex align-center"
                                  >
                                    <span class="font-weight-bold">{{
                                      capitalizeFirstLetter(
                                        formatDate(studentByDate.date)
                                      )
                                    }}</span>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-header>
                              <!-- Contenido de las clases -->
                              <v-expansion-panel-content>
                                <v-row class="mt-5 mb-0">
                                  <v-col
                                    cols="12"
                                    v-if="
                                      existInArray(
                                        'Eliminar',
                                        currentPageActions
                                      )
                                    "
                                  >
                                    <p
                                      class="text-subtitle-1"
                                      v-if="item.type == 'Permiso'"
                                    >
                                      Si deseas eliminar clases del permiso,
                                      puedes seleccionarlos y eliminar con el
                                      botón de abajo.
                                    </p>
                                    <p class="text-subtitle-1" v-else>
                                      Si deseas eliminar clases de la
                                      justificación, puedes seleccionarlos y
                                      eliminar con el botón de abajo.
                                    </p>
                                  </v-col>
                                  <StudentClassItem
                                    :methodGetStudentClassId="
                                      saveStudentClassId
                                    "
                                    :isSelected="
                                      studentClassSelected.includes(
                                        studentClass.student_class_id
                                      )
                                    "
                                    :studentClass="studentClass"
                                    v-for="studentClass in studentByDate.data"
                                    :key="studentClass.index"
                                  />
                                  <v-col
                                    cols="12"
                                    class="d-flex justify-end"
                                    v-if="
                                      existInArray(
                                        'Eliminar',
                                        currentPageActions
                                      )
                                    "
                                  >
                                    <v-slide-x-transition
                                      :leave-absolute="true"
                                    >
                                      <v-btn
                                        v-if="
                                          !checkAvailable(studentByDate.data)
                                        "
                                        @click="
                                          includeAllStudentClassInSelected(
                                            studentByDate.data
                                          )
                                        "
                                        :color="`${colorTheme} lighten-2`"
                                      >
                                        Seleccionar todas las clases
                                      </v-btn>
                                    </v-slide-x-transition>
                                    <v-slide-x-transition
                                      :leave-absolute="true"
                                    >
                                      <v-btn
                                        v-if="
                                          checkAvailable(studentByDate.data)
                                        "
                                        @click="
                                          removeStudentClassFromSelected(
                                            studentByDate.data
                                          )
                                        "
                                        :color="`${colorTheme} lighten-4`"
                                      >
                                        Deseleccionar todas las clases
                                      </v-btn>
                                    </v-slide-x-transition>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <v-container
                            class="red lighten-4 d-flex justify-center justify-md-end py-5 rounded-lg mt-3"
                            v-if="existInArray('Eliminar', currentPageActions)"
                          >
                            <v-btn
                              class="red darken-2 white--text font-weight-bold"
                              @click="deleteStudentClass()"
                              >Eliminar {{ faultTypeText }} seleccionadas</v-btn
                            >
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="mt-10" v-else>
            <v-col class="mx-auto d-flex flex-column" cols="12" sm="10">
              <div class="mx-auto mb-4 pa-8 rounded-circle green lighten-4">
                <v-icon large color="success">mdi-check-circle-outline</v-icon>
              </div>
              <div>
                <p class="text-center font-weight-bold text-h6 mb-1">
                  Sin asignaciones
                </p>
                <p class="text-center font-weight-medium text-body-1">
                  {{ emptyText }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-scroll-x-transition>
    </v-card>
  </v-dialog>
</template>

<script>
import DateDropdownMenu from "@/components/elements/justifications/DateDropdownMenu";
import StudentClassItem from "@/components/elements/justifications/justificationModal/StudentClassItem.vue";

import studentJustificationsRepository from "@/repositories/studentJustificationsRepository";

import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { mapGetters } from "vuex";

import dayjs from "dayjs";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "justificationInfoModal",
  components: {
    DateDropdownMenu,
    StudentClassItem,
  },
  props: {
    titleLabel: {
      type: String,
    },
    colorTheme: {
      type: String,
      default: "blue",
    },
    studentClassInformation: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    faultType: {
      type: Number,
      required: true,
    },
    forCoordinations: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    search: null,
    showDialog: false,
    expanded: [],
    sortBy: "created_at",
    tableHeaders: [
      {
        text: "Inicia",
        align: "start",
        sortable: true,
        value: "from_date",
      },
      {
        text: "Finaliza",
        align: "start",
        sortable: true,
        value: "to_date",
      },
      {
        text: "Tipo",
        align: "start",
        sortable: false,
        value: "type",
      },
      {
        text: "Detalle",
        groupable: false,
        value: "data-table-expand",
      },
    ],
    tableHeadersForCoordinations: [
      {
        text: "Estudiante",
        align: "start",
        sortable: true,
        value: "student_name"
      },
      {
        text: "Código",
        align: "start",
        sortable: true,
        value: "code"
      },
      {
        text: "Creador por",
        align: "start",
        sortable: true,
        value: "user"
      },
      {
        text: "Fecha creación",
        align: "start",
        sortable: true,
        value: "created_at"
      },
      {
        text: "Detalle",
        groupable: false,
        value: "data-table-expand",
      },
    ],
    studentClassSelected: [],
    searchData: {
      startDate: dayjs().format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
    },
    showStartDate: false,
    showEndDate: false,
  }),
  methods: {
    toggleModal() {
      this.showDialog = !this.showDialog;
    },
    closeModal() {
      this.showDialog = false;
      this.studentClassSelected.length = 0;
      this.sortBy = "created_at";
      this.expanded = [];
      this.searchData = {
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      }
    },
    formatDate(value) {
      return dayjs(value).format("dddd DD [de] MMMM [de] YYYY");
    },
    formatShortDate(value) {
      return dayjs(value).format("DD [de] MMMM [de] YYYY");
    },
    formatCompleteDate(value) {
      return this.fullFormattedDate(
        value,
        "dddd DD [de] MMMM [de] YYYY [a las] hh:mm a"
      );
    },
    saveStudentClassId(value) {
      const valueIndex = this.studentClassSelected.indexOf(value);
      if (this.studentClassSelected.indexOf(value) == -1) {
        return this.studentClassSelected.push(value);
      }
      return this.studentClassSelected.splice(valueIndex, 1);
    },
    deleteStudentClass() {
      if (this.studentClassSelected.length != 0) {
        Swal.fire({
          title: "Eliminación de clases del permiso",
          text: "¿Estás seguro que deseas eliminar las clases seleccionadas del permiso?",
          icon: "warning",
          confirmButtonText: "Si",
          showDenyButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            const payload = {
              student_class_ids: this.studentClassSelected,
              student_request_type_id: this.faultType,
            };
            studentJustificationsRepository
              .deleteJustifications(payload)
              .then(() => {
                this.fireToast({
                  title:
                    this.faultType == 1
                      ? "Clases removidas del permiso"
                      : "Clases removidas de la justificación",
                  icon: "success",
                });

                if (this.forCoordinations) {
                  this.$emit("reloadData", this.searchData);
                  return;
                }
                this.$emit("reloadData");
              })
              .catch((error) => {
                this.fireToast({
                  title: "Ocurrió un error el remover las clases",
                  icon: "error",
                });
              });
          }
        });
        return;
      }
      this.fireToast({
        icon: "info",
        title: "Debes de seleccionar al menos una clase antes de eliminarla.",
      });
    },
    searchForInformation() {
      this.$emit("reloadData", this.searchData);
    },
    getStudentClasses(studentArray) {
      const studentClassIds = studentArray.map((element) => {
        return element.student_class_id;
      });
      return studentClassIds;
    },
    includeAllStudentClassInSelected(studentArray) {
      const mergeArray = Array.from(
        new Set([
          ...this.studentClassSelected,
          ...this.getStudentClasses(studentArray),
        ])
      );
      this.studentClassSelected = mergeArray;
    },
    removeStudentClassFromSelected(studentArray) {
      const availableStudentClass = this.getStudentClasses(studentArray);
      const remainingStudentClasses = this.studentClassSelected.filter(
        (studentClassId) => {
          !availableStudentClass.includes(studentClassId);
        }
      );
      this.studentClassSelected = remainingStudentClasses;
    },
    checkAvailable(studentArray) {
      const studentsClassesAvailableToSelect =
        this.getStudentClasses(studentArray);
      return studentsClassesAvailableToSelect.every((studentClassId) =>
        this.studentClassSelected.includes(studentClassId)
      );
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    typeText(type) {
      if (type == "ITR") return type;
      return "";
    },
    // Methos to filter info
    removeFilter() {
      this.sortBy = null;
    },
    setExpandedRow(value) {
      this.expanded.push(
        this.studentClassInformation.find(
          (studentInfo) =>
            studentInfo.justification_permission_header_id === value
        )
      );
    },
    reorderData() {
      this.expanded = [];
      this.sortBy = "created_at";
    },
    async downloadFile(url) {
      try {
        // Crear un elemento <a> de manera dinámica
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "filename.jpeg"); // Puedes cambiar el nombre del archivo según sea necesario

        // Añadir el <a> al DOM y hacer clic en él
        document.body.appendChild(link);
        link.click();

        // Eliminar el <a> del DOM
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading file", error);
      }
    },
    allowDates(val) {
      const dateNumber = dayjs(val).day();
      return (
        dateNumber != 0 &&
        dateNumber != 6 &&
        dayjs(val).startOf("day") <= dayjs().startOf("day")
      );
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
    faultTypeText() {
      if (this.faultType == 1) {
        return "Permiso";
      }
      return "Justificación";
    },
    emptyText() {
      if (this.faultType == 1) {
        return "Sin permisos encontrados";
      }
      return "Sin justificaciones encontradas";
    },
    headers() {
      if (this.forCoordinations) {
        return this.tableHeadersForCoordinations;
      }
      return this.tableHeaders;
    }
  },
  watch: {
    studentClassInformation: {
      handler() {
        this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Panel disciplinario");
      },
      deep: true,
    },
    showDialog(newVal, oldValue) {
      if (!newVal) {
        this.expanded = [];
      }
    },
  },
};
</script>