var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"550"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"py-7"},[_vm._v("Modificación del código asignado")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-h6 font-weight-bold text-center mb-0"},[_vm._v(" Código seleccionado ")]),_c('v-list-item-group',{model:{value:(_vm.selectedCodeList),callback:function ($$v) {_vm.selectedCodeList=$$v},expression:"selectedCodeList"}},[_c('v-list',{staticClass:"v-list"},[_c('v-list-item',{staticClass:"rounded-lg",on:{"click":function($event){return _vm.showMdlSelectCode()}}},[_c('v-list-item-content',{staticClass:"pb-0"},[_c('v-list-item-subtitle',{staticClass:"mb-1"},[_c('v-chip',{staticClass:"mt-1",attrs:{"color":`${
                        _vm.setCodeTypeTheme(_vm.selectedCodeFromModal.CodeType?.name)
                          .color
                      } lighten-4 font-weight-medium`}},[_c('p',{class:`font-weight-medium ${
                          _vm.setCodeTypeTheme(
                            _vm.selectedCodeFromModal.CodeType?.name
                          ).color
                        }--text mb-0`},[_vm._v(" Código "),_c('span',{class:`text-lowercase font-weight-bold ${
                            _vm.setCodeTypeTheme(
                              _vm.selectedCodeFromModal.CodeType?.name
                            ).color
                          }--text`},[_vm._v(_vm._s(_vm.selectedCodeFromModal.CodeType?.name))])])])],1),_c('p',{staticClass:"font-weight-bold my-1 ml-3 pr-2"},[_vm._v(" "+_vm._s(_vm.selectedCodeFromModal.name)+" ")]),_c('p',{staticClass:"font-weight-medium text--secondary ml-3 pr-3"},[_vm._v(" "+_vm._s(_vm.selectedCodeFromModal.description)+" ")])],1)],1)],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('form-group',{attrs:{"name":"Observación","validator":_vm.$v.observation},scopedSlots:_vm._u([{key:"default",fn:function({ attrs }){return _c('v-textarea',_vm._b({attrs:{"counter":"","filled":"","clearable":"","rounded":"","auto-grow":"","rows":"2","label":"Observación (opcional)","placeholder":"Ej.: El estudiante..."},on:{"blur":function($event){return _vm.$v.observation.$touch()},"paste":function($event){return _vm.handlePaste($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleKeyUpMotive()}},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"observation"}},'v-textarea',attrs,false))}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{class:`${
                  _vm.setCodeTypeTheme(_vm.selectedCodeFromModal.CodeType?.name).color
                } lighten-4 mb-2`,on:{"click":function($event){return _vm.submitUpdateCode()}}},[_c('v-icon',{attrs:{"left":"","color":`${
                    _vm.setCodeTypeTheme(_vm.selectedCodeFromModal.CodeType?.name)
                      .color
                  }`}},[_vm._v("mdi-paperclip-plus")]),_c('span',{class:`${
                    _vm.setCodeTypeTheme(_vm.selectedCodeFromModal.CodeType?.name)
                      .color
                  }--text font-weight-bold d-none d-sm-inline`},[_vm._v("Actualizar código")])],1)],1)],1)],1)],1)],1)],1),_c('SelectCodeModal',{ref:"mdlSelectCode",attrs:{"colorTheme":"primary","codes":_vm.codes.data,"codeTypes":_vm.codeTypes},on:{"codeSelected":function($event){return _vm.setSelectedCodeDetails($event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }