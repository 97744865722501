<template>
  <div>
    <div class="white rounded-lg pa-5 py-md-5 px-md-10">
      <template>
        <!-- begin:: Header-->
        <div class="card-title pt-4">
          <h3 class="card-label">
            Panel disciplinario
            <span class="d-block text-muted pt-2 font-size-sm">Puede revisar y gestionar el control disciplinario de los
              estudiantes.
            </span>
          </h3>
        </div>
        <!--end:: Header-->

        <!-- Begin:: Student autocomplete list -->
        <v-row class="mt-5">
          <v-col cols="12">
            <v-autocomplete :label="students.areLoading
                ? 'Cargado estudiantes...'
                : 'Código o nombre de estudiante'
              " :loading="students.areLoading" :disabled="students.areLoading || !students?.data.length || !onlyOneStudent
                " :filter="customFilter" rounded filled hide-details open-on-clear clearable :items="students.data"
              item-text="code" item-value="code" return-object v-model="selectedStudent" @change="get(selectedStudent)"
              @click="wipeCardDetails()">
              <!-- begin::selected student item -->
              <template v-slot:selection="{ item, index }">
                <v-chip color="primary" small v-if="index === 0">
                  <span>{{ item.code }} -
                    {{ item.first_name + " " + item.last_name }}</span>
                </v-chip>
              </template>
              <!-- end::selected student item -->
              <template v-slot:item="{ item }">
                <v-row>
                  <v-col class="student-divider mt-3 mb-3">
                    <v-row>
                      <!-- student photo -->
                      <!-- -------------------------- -->
                      <v-col cols="11" sm="2" lg="1"
                        class="ml-2 d-flex flex-column justify-center justify-sm-start align-center justify-lg-end pb-1">
                        <v-avatar size="60">
                          <img :src="`${item.photo}`" :alt="`${item.full_name}`" />
                        </v-avatar>
                        <v-chip x-small class="ma-2" :color="`${item.status == 'Activo' ? 'success' : 'red darken-1'
                          }`" outlined>
                          <span class="font-weight-bold">{{
                            item.status
                            }}</span>
                        </v-chip>
                      </v-col>
                      <!-- -------------------------- -->
                      <v-col cols="11" sm="3" md="2" class="ml-2 d-flex justify-center align-center py-0">
                        <span class="font-weight-bold text-h6 text-center">
                          {{ item.code }}
                        </span>
                      </v-col>
                      <!-- -------------------------- -->
                      <v-col cols="11" sm="6" md="4" lg="3" class="ml-2 d-flex justify-center align-center pt-0 pb-1">
                        <span class="text-uppercase font-weight-medium text-center">
                          {{ item.first_name }} {{ item.last_name }}
                        </span>
                      </v-col>
                      <!-- -------------------------- -->
                      <v-col cols="11" sm="5" md="3"
                        class="ml-2 d-flex justify-center align-center pt-1 pb-1 pb-sm-4 text-center">
                        <strong class="ml-1">{{ item.grade }}</strong> -
                        <strong class="ml-1">{{ item.section_group }}</strong>
                      </v-col>
                      <!-- -------------------------- -->
                      <v-col cols="11" sm="6" md="12" lg="2"
                        class="ml-2 d-flex justify-center justify-sm-start align-center justify-lg-end pb-4 pt-0 text-center">
                        <strong class="ml-1">{{ item.technical_group }}</strong>
                      </v-col>
                      <!-- -------------------------- -->
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <!-- End:: Student autocomplete list -->

        <!-- Begin:: Start/End date or stage selectors-->
        <v-row class="mt-5 d-flex justify-center">
          <!-- Start date -->
          <v-col cols="12" sm="6" md="4">
            <v-menu v-model="fromDateMenuState" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :disabled="studentHasNotBeenSelected"
                  v-model="studentDisciplineTimestampMetadata.startDate" label="Desde"
                  prepend-inner-icon="mdi-calendar-start" readonly v-bind="attrs" v-on="on" rounded
                  filled></v-text-field>
              </template>
              <v-date-picker :min="`${new Date().getFullYear()}-01-01`" :allowed-dates="allowDates"
                v-model="studentDisciplineTimestampMetadata.startDate"
                @input="(fromDateMenuState = false), resetEndingDate()"></v-date-picker>
            </v-menu>
          </v-col>

          <!-- End date -->
          <v-col cols="12" sm="6" md="4">
            <v-menu v-model="toDateMenuState" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :disabled="studentHasNotBeenSelected && startDateHasNotBeenSelected
                  " v-model="studentDisciplineTimestampMetadata.endDate" label="Hasta"
                  prepend-inner-icon="mdi-calendar-end" readonly v-bind="attrs" v-on="on" rounded filled></v-text-field>
              </template>
              <v-date-picker :min="studentDisciplineTimestampMetadata.startDate"
                :max="`${new Date().getFullYear()}-12-31`" :allowed-dates="allowDates"
                v-model="studentDisciplineTimestampMetadata.endDate" @input="
                  (toDateMenuState = false),
                  getDisciplineDetailsFromTimePeriod()
                  "></v-date-picker>
            </v-menu>
          </v-col>

          <!-- Stage selector -->
          <v-col cols="12" md="4">
            <v-select :disabled="studentHasNotBeenSelected" class="" :items="sortedStages" item-text="name" filled
              rounded dense v-model="selectedStudentStages.selectedStage" return-object
              @change="setStagePeriodAsDateRangeForDisciplineDetails()" label="Etapa">
              <template v-slot:item="{ item }">
                <p class="text-capitalize font-weight-medium text-body-1 mb-0">
                  <v-icon :color="stageColor[sortedStages.indexOf(item) + 1]">mdi-numeric-{{
                    sortedStages.indexOf(item) + 1
                  }}-circle-outline</v-icon>
                  <span class="ml-2 mb-n1">{{ item.name }}</span>
                </p>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <!-- End:: Start/End date or stage selectors-->

        <v-row class="mt-5">
          <v-col cols="12">
            <!-- Begin:: Card collection that shows discipline sections-->
            <v-container v-if="allValidInputs">
              <v-row v-if="
                !isLoadingStudentDisciplineInformation &&
                !nursingVisits.isLoading
              ">
                <!-- Begin:: Selected student details -->
                <v-col cols="12" class="mb-6 pb-5 divider-bottom">
                  <v-card class="mb-4 elevation-0">
                    <v-row>
                      <!-- Student picture -->
                      <div class="d-flex justify-lg-start justify-md-start justify-sm-center pl-7 pl-md-3 pr-0">
                        <v-avatar size="150">
                          <v-img :src="selectedStudent.photo"></v-img>
                        </v-avatar>

                        <!-- <v-img
                          class="rounded"
                          max-width="200"
                          aspect-ratio="0.80"
                          :src="selectedStudent.photo"
                        ></v-img> -->
                      </div>

                      <!-- Student details -->
                      <div class="pa-5 d-flex flex-column align-lg-start align-md-start align-sm-center">
                        <v-row class="pl-md-5 pl-lg-5 pl-sm-0">
                          <v-col cols="12">
                            <p class="font-weight-bold text-body-1 mb-1">
                              {{ selectedStudent?.code || "No especificado" }}
                              <v-icon class="mb-1">mdi-circle-small</v-icon>
                              {{
                                `${selectedStudent?.grade} ${selectedStudent?.technical_group} -
                              ${selectedStudent?.section_group}`
                              }}
                            </p>
                            <p class="font-weight-medium text-h4">
                              {{
                                `${selectedStudent?.first_name} ${selectedStudent?.last_name}` ||
                                "No especificado"
                              }}
                            </p>

                            <div
                              class="d-flex flex-column align-lg-start align-md-start align-sm-center justify-center">
                              <a :href="`mailto:${selectedStudent?.email
                                  ? selectedStudent?.email
                                  : 'depto_desarrollo@ricaldone.edu.sv'
                                }`" class="font-weight-medium text-body-1">
                                <v-icon color="blue">mdi-email</v-icon>
                                {{
                                  selectedStudent?.email || "No especificado"
                                }}
                              </a>
                              <div>
                                <v-btn class="mt-3 blue white--text" @click="downloadReport(true)"
                                  :disabled="disableViewButton" :loading="disableDownloadButton">Descargar reporte
                                  pdf</v-btn>
                                <v-btn class="mt-3 cyan white--text ml-1" @click="downloadReport(false)"
                                  :disabled="disableDownloadButton" :loading="disableViewButton">Visualizar
                                  reporte</v-btn>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-row>
                  </v-card>
                </v-col>
                <!-- End:: Selected student details -->

                <!-- Begin:: Discipline cards -->
                <v-row>
                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-card outlined class="px-5 py-5 mb-5 rounded-lg">
                      <IconCardWithActionButtonVertical colorTheme="cyan" title="Llegadas tarde"
                        :subTitle="lateArrivalAmount" icon="clock-alert-outline"
                        @openDetailsModal="openLateArrivalsModal()" />
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-card outlined class="px-5 py-5 mb-5 rounded-lg">
                      <IconCardWithActionButtonVertical colorTheme="light-blue" title="Inasistencias"
                        :subTitle="absencesAmount" icon="account-remove" @openDetailsModal="openAbsencesModal()" />
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-card outlined class="px-5 py-5 mb-5 rounded-lg">
                      <IconCardWithActionButtonVertical colorTheme="blue" title="Códigos" :subTitle="codesAmount"
                        icon="file-sign" @openDetailsModal="openAssignedCodesModal()" />
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-card outlined class="px-5 py-5 mb-5 rounded-lg">
                      <IconCardWithActionButtonVertical colorTheme="indigo" title="Permisos"
                        :subTitle="permissionAmount" icon="clock-check-outline"
                        @openDetailsModal="openPermissionModal" />
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-card outlined class="px-5 py-5 mb-5 rounded-lg">
                      <IconCardWithActionButtonVertical colorTheme="blue" title="Justificaciones"
                        :subTitle="justificationAmount" icon="clock-check-outline"
                        @openDetailsModal="openJustificationModal" />
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-card outlined class="px-7 py-5 rounded-lg">
                      <IconCardWithActionButtonVertical colorTheme="blue-grey" title="Observaciones"
                        :subTitle="observationsAmount" icon="text-box-search"
                        @openDetailsModal="openObservationsModal" />
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="6" lg="4" xl="3">
                    <v-card outlined class="px-7 py-5 rounded-lg">
                      <IconCardWithActionButtonVertical colorTheme="teal" title="Visitas a unidad de primeros auxilios"
                        :subTitle="amountOfNursingVisits" icon="text-box-search"
                        @openDetailsModal="openNursingVisitisModal" />
                    </v-card>
                  </v-col>
                </v-row>
                <!-- End:: Discipline cards -->
              </v-row>

              <template v-else>
                <LoadingComponent text="Buscando informe disciplinario..." loadingBarColor="cyan" />
              </template>
            </v-container>
            <!-- End:: Card collection that shows discipline sections-->

            <!-- Begin:: Fallback text when there is invalid/empty inputs-->
            <v-container v-else class="d-flex flex-column py-10 align-center">
              <v-avatar color="green lighten-4" size="96" class="mt-10 mb-5">
                <v-icon large color="green">
                  mdi-account-search-outline
                </v-icon>
              </v-avatar>

              <p class="text-body-2 text-center">
                Puede encontrar un estudiante por medio de su nombre, apellido
                y/o código.
              </p>
            </v-container>

            <!-- End:: Fallback text when there is invalid/empty inputs-->
          </v-col>
        </v-row>
      </template>
    </div>

    <!-- Begin: Managment justifications-->
    <JustificationModal ref="justificationModal" titleLabel="Historial de justificaciones" colorTheme="light-blue"
      :studentClassInformation="justificationInfo.data" :isLoading="justificationInfo.isLoading"
      @reloadData="loadJustifications" :faultType="2" />
    <!-- End: Managment justifications-->

    <!-- Begin: Managment permissions-->
    <!-- <JustificationModal
      ref="permissionsModal"
      titleLabel="Historial de permisos"
      colorTheme="green"
      :studentClassInformation="permissionInfo.data"
      :isLoading="permissionInfo.isLoading"
      @reloadData="loadPermissions"
      :faultType="1"
    /> TODO:COMPONENTE VIEJO PARA CARGAR PERMISOS-->
    <PermissionModal ref="permissionsModal" titleLabel="Historial de permisos" colorTheme="green"
      :studentClassInformation="permissionInfo.data" :isLoading="permissionInfo.isLoading" @reloadData="loadPermissions"
      :faultType="1"></PermissionModal>
    <!-- NUEVO COMPONENTE PARA CARGAR LOS PERMISOS -->
    <!-- End: Managment permissions-->

    <!--Begin:: Justification header-->
    <JustificationHeaderModal titleLabel="Información adicional" ref="headerModal" :headerData="studentHeaderInfo"
      :action="showCompleteReport" />
    <!-- End:: Justification header -->

    <NursingVisitsDetailModal ref="mdlNursingVisitsDetail" :nursingVisits="nursingVisits.data"
      :selectedStage="selectedStudentStages.selectedStage" :dateRange="studentDisciplineTimestampMetadata"
      colorTheme="teal">
    </NursingVisitsDetailModal>

    <LateArrivalsDetailsModal ref="mdlLateArrivalsDetailedList" :lateArrivals="disciplineStats.data?.late_arrivals"
      :selectedStage="selectedStudentStages.selectedStage" :dateRange="studentDisciplineTimestampMetadata"
      colorTheme="red" @removeAbsence="removeStudentClassFromLateArrivals"></LateArrivalsDetailsModal>

    <AbsencesDetailsModal ref="mdlAbsencesDetailedList" :missingClasses="disciplineStats.data?.absences"
      :selectedStage="selectedStudentStages.selectedStage" :dateRange="studentDisciplineTimestampMetadata"
      colorTheme="deep-orange" @openJustificationHeaderModal="openJustificationHeaderModal"></AbsencesDetailsModal>

    <AssignedCodesDetailsModal ref="mdlAssignedCodesDetailedList" :assignedCodes="disciplineStats.data?.assigned_codes"
      :selectedStage="selectedStudentStages.selectedStage" :dateRange="studentDisciplineTimestampMetadata"
      colorTheme="blue" @openUpdateModal="openUpdateCodeDetailsModal" @removeCodes="removeAssignedCodes">
    </AssignedCodesDetailsModal>

    <!-- Begin:: Update assigned code modal -->
    <UpdateCodeDetailsModal ref="mdlUpdateCodeDetails" :currentCodeObservation="selectedCode?.observation"
      :currentCode="selectedCode" @updateCode="updateCodeDetails" />
    <UpdateObservationDetailsModal ref="mdlUpdateObservation" :currentObservation="selectedObservation"
      @updateObservation="updateObservationDetails" />
    <!-- End:: Update assigned code modal -->
    <ObservationsModal ref="mdlAssignedObservationsList" :observations="disciplineStats.data?.observations"
      :selectedStage="selectedStudentStages.selectedStage" :dateRange="studentDisciplineTimestampMetadata"
      colorTheme="deep-purple" @removeObservation="removeObservation"
      @openUpdateObservationModal="openUpdateObservationModal"></ObservationsModal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import JwtService from "@/core/services/jwt.service";

import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");

// Repositories
import studentRepository from "@/repositories/studentRepository";
import nursingRepository from "@/repositories/nursingRepository";
import studentDisciplineReportRepository from "@/repositories/studentDisciplineReportRepository";
import studentJustificationsRepository from "@/repositories/studentJustificationsRepository";

// Components
import DateDropdownMenu from "@/components/elements/justifications/DateDropdownMenu";
import LoadingComponent from "@/components/elements/justifications/LoadingComponent";
import JustificationModal from "@/components/modals/justification_permission/JustificationModal";
import PermissionModal from "@/components/modals/justification_permission/PermissionModal";

import JustificationHeaderModal from "@/components/modals/justification_permission/JustificationHeaderModal";
import UpdateCodeDetailsModal from "@/components/modals/student_discipline_dashboard/UpdateCodeDetailsModal.vue";
import IconCardWithAction from "@/components/elements/dashboard/IconCardWithAction.vue";
import IconCardWithActionButtonVertical from "@/components/elements/dashboard/IconCardWithBottomAction.vue";

import { required } from "vuelidate/lib/validators";
import AbsencesDetailsModal from "@/components/modals/student_discipline_dashboard/AbsencesDetailsModal.vue";
import LateArrivalsDetailsModal from "@/components/modals/student_discipline_dashboard/LateArrivalsDetailsModal.vue";
import AssignedCodesDetailsModal from "@/components/modals/student_discipline_dashboard/AssignedCodesDetailsModal.vue";
import NursingVisitsDetailModal from "@/components/modals/student_discipline_dashboard/NursingVisitsDetailModal.vue";
import ObservationsModal from "@/components/modals/student_discipline_dashboard/AssignedObservationsModal.vue";
import UpdateObservationDetailsModal from "@/components/modals/student_discipline_dashboard/UpdateObservationDetailsModal.vue";
import axios from "axios";
import CouldNotSetFirstStepInStudentFormErrorModalVue from '../../../components/modals/enrollment_process/CouldNotSetFirstStepInStudentFormErrorModal.vue';

export default {
  name: "DisciplineDashboard",
  title: "Panel disciplinario | GE ITR",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Panel disciplinario", route: "discipline_dashboard" },
    ]);
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Permisos");
    this.loadStudents();
  },

  watch: {
    selectedStudent(newValue, oldValue) {
      if (newValue === null) {
        this.wipeCardDetails();
      }
    },
  },

  components: {
    DateDropdownMenu,
    LoadingComponent,
    IconCardWithAction,
    IconCardWithActionButtonVertical,
    JustificationModal,
    PermissionModal,
    JustificationHeaderModal,
    AbsencesDetailsModal,
    LateArrivalsDetailsModal,
    AssignedCodesDetailsModal,
    UpdateCodeDetailsModal,
    ObservationsModal,
    UpdateObservationDetailsModal,
    NursingVisitsDetailModal,
  },

  data: () => ({
    students: {
      areLoading: false,
      data: [],
    },

    selectedStudentStages: {
      areLoading: false,
      data: [],
      selectedStage: {},
    },

    disciplineStats: {
      areLoading: false,
      data: {},
    },

    permissionInfo: {
      data: [],
      isLoading: false,
    },

    justificationInfo: {
      data: [],
      isLoading: false,
    },

    nursingVisits: {
      data: [],
      isLoading: false,
    },

    stageColor: {
      1: "blue lighten-1",
      2: "blue darken-2",
      3: "blue darken-4",
      4: "indigo darken-4",
      5: "deep-purple darken-4",
    },

    allValidInputs: false,

    disableMode: false,

    studentInfo: {},

    studentHeaderInfo: {},

    selectedStudent: {},

    // For update code
    selectedCode: {},
    selectedObservation: {},

    onlyOneStudent: true,

    fromDateMenuState: false,

    toDateMenuState: false,

    disableDownloadButton: false,
    disableViewButton: false,

    studentDisciplineTimestampMetadata: {
      studentUUID: null,
      startDate: null,
      endDate: null,
    },
  }),
  methods: {
    /*If there's a code available when an "onChange" event takes
      place, the request will be made. otherwise, dont.*/
    get(student) {
      if (student != null) {
        //request functions
        this.getStudentStages();
        return;
      }
    },

    setStagePeriodAsDateRangeForDisciplineDetails() {
      this.studentDisciplineTimestampMetadata.studentUUID =
        this.selectedStudent.uuid;

      this.studentDisciplineTimestampMetadata.startDate =
        this.selectedStudentStages.selectedStage.start;

      this.studentDisciplineTimestampMetadata.endDate =
        this.selectedStudentStages.selectedStage.end;

      this.$v.studentDisciplineTimestampMetadata.$touch();

      this.getDisciplineDetailsFromTimePeriod();
    },

    resetEndingDate() {
      this.studentDisciplineTimestampMetadata.endDate = undefined;
    },

    refreshObservations() {
      this.openObservationsModal();
      this.getDisciplineDetailsFromTimePeriod();
      this.openObservationsModal();
      this.fireToast({
        icon: "success",
        title: `Observación eliminada correctamente`,
      });
    },

    loadStudents() {
      this.students.areLoading = true;
      studentRepository
        .getAllStudents()
        .then(({ data }) => {
          this.students.data = data;
        })
        .catch(() =>
          this.fireToast({
            icon: "error",
            title: "No se han podido cargar los estudiantes",
          })
        )
        .finally(() => (this.students.areLoading = false));
    },

    getStudentStages() {
      this.selectedStudentStages.areLoading = true;

      studentDisciplineReportRepository
        .getStudentStagesByGrade(this.selectedStudent.grade_id)
        .then(({ data }) => {
          this.selectedStudentStages.data = data;

          const completeYearStage = {
            start: data.sortedStages[0].start,
            start_formatted: data.sortedStages[0].start_date,
            end: '2024-12-31',
            end_formatted: '31 de diciembre de 2024',
            id: 100,
            name: 'Año completo',
            percentage: 100,
            can_be_showed_in_report_card: data.sortedStages[data.sortedStages.length - 1].can_be_showed_in_report_card,
            has_finalized: data.sortedStages[data.sortedStages.length - 1].has_finalized,
            stage_type_id: data.sortedStages[data.sortedStages.length - 1].stage_type_id,
            calculate_job: data.sortedStages[data.sortedStages.length - 1].calculate_job,
          };

          this.selectedStudentStages.data?.sortedStages.push(completeYearStage)


          this.studentDisciplineTimestampMetadata.studentUUID =
            this.selectedStudent.uuid;

          this.studentDisciplineTimestampMetadata.startDate =
            this.currentStageStartDate;

          this.studentDisciplineTimestampMetadata.endDate =
            this.currentStageEndDate;

          this.$v.studentDisciplineTimestampMetadata.$touch();

          this.getDisciplineDetailsFromTimePeriod();

          this.loadNursingVisits();
        })
        .catch((error) => {
          console.warn(error);
        })
        .finally(() => {
          this.selectedStudentStages.areLoading = false;
        });
    },

    getDisciplineDetailsFromTimePeriod() {
      this.$v.studentDisciplineTimestampMetadata.$touch();

      if (!this.$v.studentDisciplineTimestampMetadata.$invalid) {
        this.allValidInputs = true;
        this.disciplineStats.areLoading = true;
        this.disciplineStats.data = {};

        studentDisciplineReportRepository
          .getStudentDisciplineDetailsByTimePeriod(
            this.studentDisciplineTimestampMetadata.studentUUID,
            this.studentDisciplineTimestampMetadata.startDate,
            this.studentDisciplineTimestampMetadata.endDate
          )
          .then(({ data }) => {
            this.disciplineStats.data = data.data;
          })
          .catch((error) => {
            console.log("error", error);
          })
          .finally(() => {
            this.disciplineStats.areLoading = false;
          });

        // Default payload for justifications and permissions

        this.loadJustifications();
        // Get permission info
        this.loadPermissions();
        // Set student information
        this.studentInfo = this.studentDisciplineTimestampMetadata.studentUUID;

        return;
      }
      this.allValidInputs = false;
      this.disciplineStats.areLoading = false;
    },

    loadNursingVisits() {
      this.nursingVisits.isLoading = true;
      const payload = {
        start_date: this.studentDisciplineTimestampMetadata.startDate,
        end_date: this.studentDisciplineTimestampMetadata.endDate,
        student_id: this.studentDisciplineTimestampMetadata.studentUUID,
      };
      nursingRepository
        .getStudentsClinicVisitsByRange(
          payload.student_id,
          payload.start_date,
          payload.end_date
        )
        .then(({ data }) => {
          this.nursingVisits.data = data.data;
        })
        .catch((error) => {
          console.log("error: ", error);
        })
        .finally(() => {
          this.nursingVisits.isLoading = false;
        });
    },

    loadJustifications() {
      const payload = {
        start_date: this.studentDisciplineTimestampMetadata.startDate,
        end_date: this.studentDisciplineTimestampMetadata.endDate,
        student_id: this.studentDisciplineTimestampMetadata.studentUUID,
        student_request_type_id: 2,
      };
      // Reset data
      this.justificationInfo.data = [];
      studentJustificationsRepository
        .getJustifications(payload)
        .then(({ data }) => {
          if (data?.message) {
            this.justificationInfo.data = [];
          } else {
            this.justificationInfo.data = data.data;
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.permissionInfo.isLoading = false;
        });
    },
    loadPermissions() {
      const payload = {
        start_date: this.studentDisciplineTimestampMetadata.startDate,
        end_date: this.studentDisciplineTimestampMetadata.endDate,
        student_id: this.studentDisciplineTimestampMetadata.studentUUID,
        student_request_type_id: 1,
      };
      // Reset initial data
      this.permissionInfo.data = [];
      studentJustificationsRepository
        .getPermissions(payload)
        .then(({ data }) => {
          if (data?.message) {
            this.permissionInfo.data = [];
          } else {
            this.permissionInfo.data = data;
          }
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.permissionInfo.isLoading = false;
        });
      // Set student information
      this.studentInfo = this.studentDisciplineTimestampMetadata.studentUUID;
    },
    wipeCardDetails() {
      this.allValidInputs = false;
      this.selectedStudent = {};
      this.selectedStudentStages.selectedStage = {};
      this.disciplineStats.data = {};

      this.studentDisciplineTimestampMetadata.studentUUID = null;
      this.studentDisciplineTimestampMetadata.startDate = null;
      this.studentDisciplineTimestampMetadata.endDate = null;
    },

    customFilter(item, queryText) {
      const searchText = queryText
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const codeMatch = item.code.toString().includes(searchText);
      const firstName = item.first_name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(searchText);
      const lastName = item.last_name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(searchText);
      return codeMatch || firstName || lastName || firstName + lastName;
    },
    // Open modals methods
    openJustificationModal() {
      this.$refs.justificationModal.toggleModal();
    },
    openLateArrivalsModal() {
      this.$refs.mdlLateArrivalsDetailedList.toggleModal();
    },
    openAbsencesModal() {
      this.$refs.mdlAbsencesDetailedList.toggleModal();
    },
    openAssignedCodesModal() {
      this.$refs.mdlAssignedCodesDetailedList.toggleModal();
    },
    openPermissionModal() {
      this.$refs.permissionsModal.toggleModal();
    },
    openObservationsModal() {
      this.$refs.mdlAssignedObservationsList.toggleModal();
    },
    openJustificationHeaderModal(payload) {
      this.studentHeaderInfo = payload;
      this.$refs.headerModal.toggleModal();
    },
    openUpdateCodeDetailsModal(codeSelected) {
      this.selectedCode = codeSelected;
      this.$refs.mdlUpdateCodeDetails.toggleModal();
    },
    openUpdateObservationModal(selectedObservation) {
      this.selectedObservation = { ...selectedObservation };
      this.$refs.mdlUpdateObservation.toggleModal();
    },
    openNursingVisitisModal() {
      this.$refs.mdlNursingVisitsDetail.toggleModal();
    },
    showCompleteReport(value, headerData) {
      if (value === 1) {
        const headerindex = this.permissionInfo.data.findIndex(
          (justification) =>
            justification.justification_permission_header_id === headerData.id
        );
        if (headerindex !== -1) {
          const headerSelected = this.permissionInfo.data.splice(
            headerindex,
            1
          );
          this.$refs.permissionsModal.removeFilter();
          this.permissionInfo.data.unshift(headerSelected[0]);
          this.$refs.permissionsModal.setExpandedRow(headerData.id);
        }
        this.$refs.permissionsModal.toggleModal();
      }
      if (value === 2) {
        const headerindex = this.justificationInfo.data.findIndex(
          (justification) =>
            justification.justification_permission_header_id === headerData.id
        );
        if (headerindex !== -1) {
          const headerSelected = this.justificationInfo.data.splice(
            headerindex,
            1
          );
          this.justificationInfo.data.unshift(headerSelected[0]);
          this.$refs.justificationModal.removeFilter();
          this.$refs.justificationModal.setExpandedRow(headerData.id);
        }
        this.$refs.justificationModal.toggleModal();
      }
      // Close header modal
      this.$refs.headerModal.toggleModal();
      this.$refs.mdlAbsencesDetailedList.toggleModal();
    },

    updateCodeDetails(payload) {
      let codeSelected = null;
      if (payload.place === "Aula") {
        codeSelected = this.disciplineStats.data.assigned_codes.aula;
        const currentCode = codeSelected.filter(
          (code) =>
            code.studentAssignedCode.AssignedCodeDetail == payload.current
        );
        const index = codeSelected.indexOf(currentCode[0]);
        codeSelected =
          codeSelected[index].studentAssignedCode.AssignedCodeDetail;
      }
      if (payload.place === "Ex-aula") {
        codeSelected = this.disciplineStats.data.assigned_codes.ex_aula;
        const currentCode = codeSelected.filter(
          (code) =>
            code.studentAssignedCode.AssignedCodeDetail == payload.current
        );
        const index = codeSelected.indexOf(currentCode[0]);
        codeSelected =
          codeSelected[index].studentAssignedCode.AssignedCodeDetail;
      }
      codeSelected.observation = payload.observation;
      codeSelected.Code = payload.code;
    },

    updateObservationDetails(payload) {
      const index = this.disciplineStats.data?.observations.findIndex(
        (observation) => observation.id === payload.id
      );

      if (index !== -1) {
        this.disciplineStats.data.observations.splice(index, 1, payload);
      } else {
        this.disciplineStats.data.observations.push(payload);
      }
    },

    removeStudentClassFromLateArrivals(payload) {
      this.disciplineStats.data.late_arrivals[payload.type][
        payload.status
      ].splice(payload.index, 1);
    },

    removeAssignedCodes(payload) {
      this.disciplineStats.data.assigned_codes[payload.place].splice(
        payload.index,
        1
      );
    },

    removeObservation(payload) {
      this.disciplineStats.data.observations.splice(payload.index, 1);
    },

    allowDates(val) {
      const dateNumber = dayjs(val).day();
      return dateNumber != 0 && dateNumber != 6;
    },
    // Initial data methods

    // Validations methods
    calcDatesDiff(stringDate, secondStringDate = dayjs().format("YYYY-MM-DD")) {
      return (
        dayjs(stringDate)
          .startOf("day")
          .diff(dayjs(secondStringDate).startOf("day"), "day") >= 0
      );
    },

    async downloadReport(isForDownload) {
      if (isForDownload) {
        this.disableDownloadButton = true;
      } else {
        this.disableViewButton = true;
      }
      axios({
        url: `${this.DP_URL}/student-class/document-report/student/${this.selectedStudent.uuid}/start-date/${this.studentDisciplineTimestampMetadata.startDate}/end-date/${this.studentDisciplineTimestampMetadata.endDate}`,
        method: "GET",
        headers: { Authorization: `${JwtService.getToken()}` },
        responseType: "blob",
      })
        .then((response) => {
          if (isForDownload) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${this.selectedStudent.full_name} ${this.selectedStudent.code
              } ${dayjs().format("DD-MM-YYYY")}.pdf`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return;
          }
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf;charset=utf-8" })
          );
          window.open(url, "_blank");
        })
        .catch((e) => {
          console.log(e)
          this.fireToast({
            title: "Ocurrió un error al descargar el reporte",
          });
        })
        .finally(() => {
          this.disableDownloadButton = false;
          this.disableViewButton = false;
        });
    },
  },
  computed: {
    DP_URL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.DP_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DP_DEV_API_URL;
      } else {
        baseURL = process.env.DP_LOCAL_API_URL;
      }
      return baseURL;
    },
    sortedStages() {
      //Add the "all stages" option
      const allStagesOption = {}
      return this.selectedStudentStages.data?.sortedStages;
    },
    studentHasNotBeenSelected() {
      return (
        this.selectedStudent?.uuid === null ||
        this.selectedStudent?.uuid === undefined
      );
    },
    startDateHasNotBeenSelected() {
      return (
        this.studentDisciplineTimestampMetadata.startDate === null ||
        this.studentDisciplineTimestampMetadata.startDate === undefined
      );
    },
    currentDate() {
      return new Date().toISOString().split("T")[0];
    },
    currentStageStartDate() {
      return this.selectedStudentStages.data?.activeStage?.start;
    },
    currentStageEndDate() {
      return this.selectedStudentStages.data?.activeStage?.end;
    },

    isLoadingStudentDisciplineInformation() {
      return (
        this.selectedStudentStages.areLoading || this.disciplineStats.areLoading
      );
    },
    // Subtitle amount
    lateArrivalAmount() {
      const amountClassUnjustified =
        this.disciplineStats.data.late_arrivals.to_class.unjustified.length;
      const amountSchoolUnjustified =
        this.disciplineStats.data.late_arrivals.to_school.unjustified.length;
      return `${amountClassUnjustified + amountSchoolUnjustified
        } llegadas tarde.`;
    },
    absencesAmount() {
      const amountClassUnjustified =
        this.disciplineStats.data.absences.to_class.unjustified.length;
      const amountSchoolUnjustified =
        this.disciplineStats.data.absences.to_school.unjustified.length;
      return `${amountClassUnjustified + amountSchoolUnjustified
        } inasistencias.`;
    },
    codesAmount() {
      const exAulaUnjustified =
        this.disciplineStats.data.assigned_codes.aula.length;
      const aulaUnjustified =
        this.disciplineStats.data.assigned_codes.ex_aula.length;
      return `${exAulaUnjustified + aulaUnjustified} códigos.`;
    },
    justificationAmount() {
      const justificationAmount = this.justificationInfo.data?.length;
      return `${justificationAmount} justificaciones.`;
    },
    permissionAmount() {
      const permissionAmount = this.permissionInfo.data?.length;
      return `${permissionAmount} permisos.`;
    },
    observationsAmount() {
      const observationsAmount = this.disciplineStats.data.observations.length;
      return `${observationsAmount} observaciones.`;
    },
    amountOfNursingVisits() {
      let amountOfVisits = this.nursingVisits.data.length;
      return `${amountOfVisits} visitas`;
    },

    studentMessages() {
      const errors = [];
      if (!this.$v.dataSelection.student_id.$dirty) return errors;
      if (!this.$v.dataSelection.student_id.required)
        errors.push("Es necesario que seleccione al estudiante.");
      return errors;
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
  validations: {
    studentDisciplineTimestampMetadata: {
      studentUUID: {
        required,
      },
      startDate: {
        required,
        checkEndDate(val) {
          return this.calcDatesDiff(
            this.studentDisciplineTimestampMetadata.endDate,
            val
          );
        },
      },
      endDate: {
        required,
        checkStartDate(val) {
          return this.calcDatesDiff(
            val,
            this.studentDisciplineTimestampMetadata.startDate
          );
        },
      },
    },
  },
};
</script>

<style scoped>
.divider-bottom {
  border-bottom: 2px solid rgb(225, 225, 225);
}
</style>
