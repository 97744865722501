<template>
  <v-dialog v-model="dialogActiveState" max-width="600px" persistent>
    <v-card>
      <v-toolbar dark :color="`${colorTheme} lighten-1`">
        <v-btn text dark @click="toggleModal()">
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-scroll-x-reverse-transition>
            <!-- begin:: set selected code action button at modal corner -->
            <v-btn
              v-if="aCodeHasBeenSelected"
              dark
              text
              :class="
                `${
                  setCodeTypeTheme(selectedCode.CodeType?.name).color
                } lighten-4 font-weight-bold mr-n4`
              "
              @click="setSelectedCode()"
            >
              <!-- Button text - action-->
              <div class="d-flex flex-column">
                <span
                  :class="
                    `${
                      setCodeTypeTheme(selectedCode.CodeType?.name).color
                    }--text text-body-1 font-weight-bold`
                  "
                  >Seleccionar</span
                >

                <!-- Button text - type of selected code-->

                <span
                  :class="
                    `${
                      setCodeTypeTheme(selectedCode.CodeType?.name).color
                    }--text text-body-2 font-weight-medium`
                  "
                  >Código {{ selectedCode.CodeType?.name }}</span
                >
              </div>

              <!-- Icon of action -->
              <v-icon
                right
                :color="
                  `${setCodeTypeTheme(selectedCode.CodeType?.name).color}`
                "
                >mdi-arrow-right</v-icon
              >
            </v-btn>
            <!-- end:: set selected code action button at modal corner -->
          </v-scroll-x-reverse-transition>
        </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <v-row>
          <v-col>
            <p
              class="mb-0 mt-3 text-center text-h5 text-md-h4 font-weight-medium"
            >
              Seleccione un código
            </p>
          </v-col>
        </v-row>
        <!--begin::filters and search-->
        <v-container class="pa-4">
          <!-- if the search box is being used, the select for code types is disabled and viceversa. -->
          <v-row class="align-items-center mb-0">
            <!-- begin::search box-->
            <v-col cols="12">
              <div class="d-flex align-items-center">
                <!-- begin:: academicGroup combo -->
                <v-text-field
                  rounded
                  dense
                  filled
                  hide-details
                  prepend-icon=""
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Buscar código"
                  v-model="filters.search"
                  :disabled="canUseSearch"
                ></v-text-field>
                <!-- end:: academicGroup combo -->
              </div>
              <v-scroll-x-reverse-transition
                :hide-on-leave="true"
                :leave-absolute="true"
              >
                <p
                  v-if="!!filters.search"
                  class="text-body-2 text-center mb-n4 mt-2"
                >
                  {{ filteredCodes.length }}
                  {{
                    filteredCodes.length > 1 || filteredCodes.length == 0
                      ? "resultados encontrados"
                      : "resultado encontrado"
                  }}
                </p>
              </v-scroll-x-reverse-transition>
              <v-scroll-x-reverse-transition
                :hide-on-leave="true"
                :leave-absolute="true"
              >
                <p
                  v-if="!filters.search"
                  class="text-body-2 text-center mb-n4 mt-2"
                >
                  {{ filteredCodes.length }} códigos en total
                </p>
              </v-scroll-x-reverse-transition>
            </v-col>
            <!-- end::search box-->
          </v-row>
        </v-container>
        <!-- end::filters and search-->
        <v-row class="px-4 mb-4">
          <v-col cols="12">
            <v-card outlined class="elevation-0 py-0 pl-0 pr-0 mt-2 rounded-lg">
              <!-- this component contains the update modal (UpdateCodeModal); and the
            update modal contains the delete option. -->

              <v-scroll-x-reverse-transition
                :hide-on-leave="true"
                :leave-absolute="true"
              >
                <v-container v-if="filteredCodes.length < 1">
                  <v-row class="my-10">
                    <v-col class="mx-auto d-flex flex-column" cols="12" sm="10">
                      <div
                        :class="
                          `mx-auto mb-4 pa-8 rounded-circle ${colorTheme} lighten-3`
                        "
                      >
                        <v-icon x-large :color="`${colorTheme} darken-2`"
                          >mdi-help-rhombus</v-icon
                        >
                      </div>
                      <div>
                        <p class="text-center font-weight-bold text-h6 mb-1">
                          No se encontraron códigos con ese nombre.
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-scroll-x-reverse-transition>

              <v-scroll-x-reverse-transition
                :hide-on-leave="true"
                :leave-absolute="true"
              >
                <v-list-item-group
                  v-if="filteredCodes.length > 0"
                  v-model="selectedItem"
                  color="primary"
                >
                  <v-list class="v-list py-0 mx-n3">
                    <v-list-item
                      class="mx-0 mr-md-2 pr-2 pr-md-2 pl-1 pl-md-2"
                      v-for="(item, index) in filteredCodes"
                      :key="index"
                    >
                      <v-list-item-content class="pb-0">
                        <v-list-item-subtitle class="mb-1">
                          <v-chip
                            :color="
                              `${
                                setCodeTypeTheme(item.CodeType?.name).color
                              } lighten-4 font-weight-medium`
                            "
                            class="mt-1 ml-2"
                          >
                            <p
                              :class="
                                `font-weight-medium ${
                                  setCodeTypeTheme(item.CodeType?.name).color
                                }--text mb-0`
                              "
                            >
                              Código
                              <span
                                :class="
                                  `text-lowercase font-weight-bold ${
                                    setCodeTypeTheme(item.CodeType?.name).color
                                  }--text`
                                "
                                >{{ item.CodeType?.name }}</span
                              >
                            </p>
                          </v-chip>
                        </v-list-item-subtitle>

                        <p class="font-weight-bold  my-1 ml-3 pr-2">
                          {{ item.name }}
                        </p>

                        <p class="font-weight-medium text--secondary ml-3 pr-3">
                          {{ item.description }}
                        </p>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-list-item-group>
              </v-scroll-x-reverse-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SelectCodeModal",

  data() {
    return {
      dialogActiveState: false,
      selectedCode: {},
      selectedItem: "",
      search: "",
      filters: {
        search: "",
        code_type_id: null,
      },
    };
  },

  watch: {
    selectedItem(newValue, oldValue) {
      if (newValue != undefined && newValue != oldValue) {
        this.selectedCode = this.filteredCodes[newValue];
      }
    },
  },

  props: {
    colorTheme: {
      type: String,
    },
    codeTypes: {
      type: Object,
    },
    codes: {
      type: Array,
    },
  },

  methods: {
    toggleModal() {
      this.dialogActiveState = !this.dialogActiveState;
      this.selectedCode = {};
      this.selectedItem = null;
      this.filters.search = "";
    },

    setSelectedCode() {
      this.$emit("codeSelected", this.selectedCode);
      this.toggleModal();
    },

    setCodeTypeTheme(codeTypeName) {
      let codeTypeSchemas = {
        Positivo: {
          color: "green",
          icon: "star",
        },
        Leve: {
          color: "orange",
          icon: "star-minus",
        },
        Grave: {
          color: "red",
          icon: "star-minus",
        },
        "Muy grave": {
          color: "purple",
          icon: "star-minus",
        },
      };

      let defaultSchema = {
        color: "grey",
        icon: "star-minus",
      };

      if (codeTypeSchemas.hasOwnProperty(codeTypeName)) {
        return codeTypeSchemas[codeTypeName];
      } else {
        return defaultSchema;
      }
    },
  },

  computed: {
    aCodeHasBeenSelected() {
      return Object.keys(this.selectedCode).length != 0;
    },

    canFilterByType() {
      return !!this.filters.search == "";
    },

    canUseSearch() {
      return !!this.filters.code_type_id;
    },

    // Filters by search or type selection
    filteredCodes() {
      if (!!this.filters.code_type_id) {
        return this.codes.filter(
          (code) => code.code_type_id == this.filters.code_type_id
        );
      } else {
        return this.codes.filter((code) =>
          code.name.toLowerCase().includes(this.filters.search.toLowerCase())
        );
      }
    },
  },
};
</script>

<style scoped>
.v-list {
  scrollbar-width: thin !important;
  scrollbar-color: #888 #f1f1f1 !important;
  max-height: 41vh;
  overflow-y: auto;
}
</style>
