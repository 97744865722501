<template>
  <v-dialog
    v-model="dialogActiveState"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark :color="`${colorTheme} lighten-1`">
        <v-btn icon dark @click="dialogActiveState = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialogActiveState = false"> Cerrar </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- ------------------------------------------------------- -->
      <v-container v-if="allMissingClassesRecordsSorted?.length < 1">
        <v-row class="my-15">
          <v-col class="mx-auto d-flex flex-column" cols="12" sm="10">
            <div class="mx-auto mb-4 pa-8 rounded-circle green lighten-4">
              <v-icon large color="success">mdi-check-circle-outline</v-icon>
            </div>
            <div>
              <p class="text-center font-weight-bold text-h6 mb-1">
                ¡Todo Bien!
              </p>
              <p class="text-center font-weight-medium text-body-1">
                No cuenta con ausencias a clase.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- ------------------------------------------------------- -->

      <!------------ Here goes the the tab container -------------->
      <v-container v-else>
        <v-row>
          <v-col cols="12">
            <template>
              <!-- Modal title -->
              <v-toolbar
                color="white"
                class="elevation-0 rounded-lg rounded-b-0 mt-10"
              >
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      class="d-flex flex-column justify-center align-center"
                    >
                      <p class="text-h4 mb-2 mt-4 text-center">Ausencias</p>
                      <v-chip
                        class="my-2"
                        dense
                        :color="`${colorTheme} lighten-4`"
                      >
                        <span
                          :class="`font-weight-bold text-body-1 mb-0 ${colorTheme}--text`"
                          >Desde {{ dateRange?.startDate }}
                          <v-icon small>mdi-arrow-right-circle-outline</v-icon>
                          Hasta {{ dateRange?.endDate }}</span
                        >
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-container>

                <!---------begin::Tabs--------->

                <template v-slot:extension>
                  <v-tabs
                    color="blue"
                    v-model="tabs"
                    class="pt-10 d-flex justify-center"
                  >
                    <!---------------->
                    <v-tab class="text-body-1 text-capitalize font-weight-bold"
                      >A clase
                      <v-chip
                        small
                        class="ma-2"
                        color="orange"
                        text-color="white"
                        v-if="missingClasses.to_class?.unjustified.length > 0"
                      >
                        <span class="text-lowercase">
                          {{ missingClasses.to_class?.unjustified.length }} sin
                          justificar
                        </span>
                      </v-chip>
                    </v-tab>
                    <!---------------->
                    <!-- <v-tab class="text-body-1 text-capitalize font-weight-bold"
                      >A la institución<v-chip
                        small
                        class="ma-2"
                        color="orange"
                        text-color="white"
                        v-if="lateArrivals.to_school?.unjustified.length > 0"
                      >
                        <span class="text-lowercase">
                          {{ lateArrivals.to_school?.unjustified.length }} sin
                          justificar</span
                        >
                      </v-chip>
                    </v-tab> -->
                    <!---------------->
                  </v-tabs>
                </template>

                <!---------end::Tabs--------->
              </v-toolbar>

              <!--begin:: tabs-->

              <v-tabs-items
                v-model="tabs"
                class="elevation-0 rounded-lg rounded-t-0 mt-10"
              >
                <v-divider class="my-0"></v-divider>
                <!-- begin::1st tab - Late arrivals to class -->
                <v-tab-item>
                  <v-card
                    v-if="allMissingClassesRecordsSorted.length > 0"
                    class="elevation-0"
                  >
                    <v-card-title class="mb-6">
                      <v-text-field
                        v-model="searchToClass"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        rounded
                        filled
                        dense
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      class="elevation-0"
                      elevation="0"
                      :headers="headersAbsencesToClass"
                      :items="allMissingClassesRecordsSorted"
                      :search="searchToClass"
                    >
                      <template v-slot:[`item.date`]="{ item }">
                        <v-chip
                          v-if="isLicence(item)"
                          class="mt-2 mb-1 ml-n3"
                          color="green lighten-4"
                        >
                          <span class="success--text font-weight-bold"
                            >Permiso</span
                          >

                          <v-icon right color="success">
                            mdi-clock-check-outline
                          </v-icon>
                        </v-chip>
                        <p
                          :class="`black--text font-weight-medium text-capitalize text-body-1 mb-0`"
                        >
                          {{
                            fullFormattedDate(
                              item.date,
                              "dddd D [de] MMMM [del] YYYY"
                            )
                          }}
                        </p>
                      </template>
                      <template v-slot:[`item.is_justified`]="{ item }">
                        <v-tooltip
                          bottom
                          :color="`${
                            isJustified(item) ? 'success' : 'red darken-1'
                          }`"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              @click="checkJustificationOrLicenseState(item)"
                              :color="`${
                                isJustified(item)
                                  ? 'green lighten-4'
                                  : 'red lighten-4'
                              }`"
                            >
                              <v-icon
                                :color="`${
                                  isJustified(item) ? 'success' : 'red darken-1'
                                }`"
                                center
                              >
                                {{
                                  isJustified(item)
                                    ? "mdi-check-circle-outline"
                                    : "mdi-account-badge-outline"
                                }}
                              </v-icon>
                            </v-chip>
                          </template>
                          <span>{{
                            isJustified(item)
                              ? "Inasistencia justificada"
                              : "Inasistencia sin justificar"
                          }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.user_data.name`]="{ item }">
                        <p
                          class="font-weight-medium text-capitalize text-body-1 mb-0"
                        >
                          <span class="mt-2 ml-3 ml-sm-0">{{
                            item.user_data.name
                          }}</span>
                        </p></template
                      >

                      <template v-slot:[`item.schedule`]="{ item }">
                        <p
                          class="font-weight-medium text-capitalize text-body-1 mb-0"
                        >
                          {{ item.schedule }}
                        </p></template
                      >
                    </v-data-table>
                  </v-card>

                  <v-alert
                    class="mt-4"
                    v-else
                    color="green"
                    dense
                    outlined
                    prominent
                    text
                    type="success"
                    >No cuenta con llegadas tarde a clases.</v-alert
                  >
                </v-tab-item>
                <!-- end::1st tab - Late arrivals to class -->

                <!-- begin::2nd tab - Late arrivals to school -->
                <!-- <v-tab-item>
                  <v-card
                    v-if="allLateArrivalsToSchoolRecordsSorted.length > 0"
                    class="elevation-0"
                  >
                    <v-card-title class="mb-6">
                      <v-text-field
                        v-model="searchToSchool"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        rounded
                        filled
                        dense
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      class="elevation-0"
                      elevation="0"
                      :headers="headersLateArrivalsToSchool"
                      :items="allLateArrivalsToSchoolRecordsSorted"
                      :search="searchToSchool"
                      hide-default-footer
                    >
                      <template v-slot:[`item.date`]="{ item }">
                        <p
                          :class="`${colorTheme}--text text--lighten-1 font-weight-medium text-capitalize text-body-1 mb-0`"
                        >
                          {{ fullFormattedDate(item.date) }}
                        </p>
                      </template>
                      <template v-slot:[`item.is_justified`]="{ item }">
                        <v-tooltip
                          bottom
                          :color="`${
                            isJustified(item) ? 'success' : 'red darken-1'
                          }`"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              @click="checkJustificationOrLicenseState(item)"
                              :color="`${
                                isJustified(item)
                                  ? 'green lighten-4'
                                  : 'red lighten-4'
                              }`"
                            >
                              <v-icon
                                :color="`${
                                  isJustified(item) ? 'success' : 'red darken-1'
                                }`"
                                center
                              >
                                {{
                                  isJustified(item)
                                    ? "mdi-check-circle-outline"
                                    : "mdi-account-badge-outline"
                                }}
                              </v-icon>
                            </v-chip>
                          </template>
                          <span>{{
                            isJustified(item)
                              ? "Llegada tarde justificada"
                              : "Llegada tarde sin justificar"
                          }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.user_data.name`]="{ item }">
                        <p
                          class="font-weight-medium text-capitalize text-body-1 mb-0"
                        >
                          <span class="mt-2 ml-3 ml-sm-0">{{
                            item.user_data.name
                          }}</span>
                        </p></template
                      >
                    </v-data-table>
                  </v-card>

                  <v-alert
                    class="mt-4"
                    v-else
                    color="green"
                    dense
                    outlined
                    prominent
                    text
                    type="success"
                    >No cuenta con llegadas tarde a la institución en el
                    <span class="text-lowercase font-weight-bold">{{
                      selectedStage?.name
                    }}</span
                    >.</v-alert
                  >
                </v-tab-item> -->
                <!-- end::2nd tab - Late arrivals to school -->
              </v-tabs-items>

              <!--end:: tabs-->
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AbsencesDetailsModal",
  props: {
    missingClasses: {
      type: Object,
    },
    selectedStage: {
      type: Object,
    },
    dateRange: {
      type: Object,
    },
    colorTheme: {
      type: String,
    },
  },
  data() {
    return {
      JustificationOrLicenceDetailsModalTitle: "",
      dialogActiveState: false,
      tabs: [],
      selectedAbsenceRowDetails: {},
      searchToClass: "",
      headersAbsencesToClass: [
        {
          text: "Fecha",
          value: "date",
          groupable: false,
        },
        {
          text: "Aplicada por",
          value: "user_data.name",
          groupable: false,
        },
        {
          text: "Asignatura/Módulo",
          value: "subject",
          groupable: false,
        },
        {
          text: "Horario de asignatura/módulo",
          value: "schedule",
          groupable: false,
        },
        {
          text: "Estado",
          value: "is_justified",
          groupable: false,
        },
      ],
    };
  },
  methods: {
    toggleModal() {
      this.dialogActiveState = !this.dialogActiveState;
    },

    isJustified(item) {
      return (
        item.is_justified &&
        item.is_justified != null &&
        item.justification_header != null
      );
    },

    showMdlJustificationOrLicenceDetails() {
      this.$emit(
        "openJustificationHeaderModal",
        this.selectedAbsenceRowDetails
      );
    },

    isLicence(record) {
      if (this.isJustified(record)) {
        return record.justification_header.student_request_type_id === 1;
      }
      return false;
    },

    checkJustificationOrLicenseState(selectedRowDetails) {
      this.selectedAbsenceRowDetails = {};
      this.selectedAbsenceRowDetails = {
        ...selectedRowDetails.justification_header,
      };

      if (this.isJustified(selectedRowDetails)) {
        this.JustificationOrLicenceDetailsModalTitle = this.isLicence(
          selectedRowDetails
        )
          ? "Permiso de ausencia"
          : "Ausencia justificada";
        this.showMdlJustificationOrLicenceDetails();
      }
    },
  },
  computed: {
    allMissingClassesRecordsSorted() {
      let allRecords = [];

      if (this.correctClassObjectStructure) {
        allRecords = this.missingClasses.to_class?.justified.concat(
          this.missingClasses.to_class?.unjustified
        );
      }

      return this.mergeSort(allRecords, "date", "desc", "date");
    },

    correctClassObjectStructure() {
      return (
        this.missingClasses?.to_class?.justified !== undefined &&
        this.missingClasses.to_class.justified !== null &&
        this.missingClasses.to_class.unjustified !== undefined &&
        this.missingClasses.to_class.unjustified !== null
      );
    },
  },
};
</script>
