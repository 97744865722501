<template>
  <v-dialog
    v-model="dialogActiveState"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark :color="`${colorTheme} lighten-1`">
        <v-btn icon dark @click="dialogActiveState = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialogActiveState = false"> Cerrar</v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- ----------------------------------------------------- -->
      <v-container
        v-if="
          allLateArrivalsToClassRecordsSorted?.length < 1 &&
          allLateArrivalsToSchoolRecordsSorted?.length < 1
        "
      >
        <v-row class="my-15">
          <v-col class="mx-auto d-flex flex-column" cols="12" sm="10">
            <div class="mx-auto mb-4 pa-8 rounded-circle green lighten-4">
              <v-icon large color="success">mdi-check-circle-outline</v-icon>
            </div>
            <div>
              <p class="text-center font-weight-bold text-h6 mb-1">
                ¡Todo Bien!
              </p>
              <p class="text-center font-weight-medium text-body-1">
                No cuenta con llegadas tarde.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- ----------------------------------------------------- -->

      <!------------ Here goes the the tab container -------------->
      <v-container v-else>
        <v-row>
          <v-col cols="12">
            <template>
              <!-- Tabs SHOULD start here -->

              <!-- begin::filters for report generation-->
              <v-toolbar
                color="white"
                class="elevation-0 rounded-lg rounded-b-0 mt-10"
              >
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      class="d-flex flex-column justify-center align-center"
                    >
                      <p class="text-h4 mb-2 mt-4 text-center">
                        Llegadas tarde
                      </p>
                      <v-chip
                        class="my-2"
                        dense
                        :color="`${colorTheme} lighten-4`"
                      >
                        <span
                          :class="`font-weight-bold text-body-1 mb-0 ${colorTheme}--text`"
                          >Desde {{ dateRange?.startDate }}
                          <v-icon small>mdi-arrow-right-circle-outline</v-icon>
                          Hasta {{ dateRange?.endDate }}</span
                        >
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-container>

                <!---------begin::Tabs--------->

                <template v-slot:extension>
                  <v-tabs
                    color="blue"
                    v-model="tabs"
                    class="pt-10 d-flex justify-center"
                  >
                    <!---------------->
                    <v-tab class="text-body-1 text-capitalize font-weight-bold"
                      >A clase
                      <v-chip
                        small
                        class="ma-2"
                        color="orange"
                        text-color="white"
                        v-if="lateArrivals.to_class?.unjustified.length > 0"
                      >
                        <span class="text-lowercase white--text">
                          {{ lateArrivals.to_class?.unjustified.length }} sin
                          justificar
                        </span>
                      </v-chip>
                    </v-tab>
                    <!---------------->
                    <v-tab class="text-body-1 text-capitalize font-weight-bold"
                      >A la institución<v-chip
                        small
                        class="ma-2"
                        color="orange"
                        text-color="white"
                        v-if="lateArrivals.to_school?.unjustified.length > 0"
                      >
                        <span class="text-lowercase white--text">
                          {{ lateArrivals.to_school?.unjustified.length }} sin
                          justificar</span
                        >
                      </v-chip>
                    </v-tab>
                    <!---------------->
                  </v-tabs>
                </template>

                <!---------end::Tabs--------->
              </v-toolbar>

              <!--begin:: tabs-->

              <v-tabs-items
                v-model="tabs"
                class="elevation-0 rounded-lg rounded-t-0 mt-10"
              >
                <v-divider class="my-0"></v-divider>
                <!-- begin::1st tab - Late arrivals to class -->
                <v-tab-item>
                  <v-card
                    v-if="allLateArrivalsToClassRecordsSorted.length > 0"
                    class="elevation-0"
                  >
                    <v-card-title class="mb-6">
                      <v-text-field
                        v-model="searchToClass"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        rounded
                        filled
                        dense
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      class="elevation-0"
                      elevation="0"
                      :headers="headersLateArrivalsToClass"
                      :items="allLateArrivalsToClassRecordsSorted"
                      :search="searchToClass"
                      hide-default-footer
                    >
                      <template v-slot:[`item.date`]="{ item }">
                        <v-chip
                          v-if="isLicence(item)"
                          class="mt-2 mb-1 ml-n3"
                          color="green lighten-4"
                        >
                          <span class="success--text font-weight-bold"
                            >Permiso</span
                          >

                          <v-icon right color="success">
                            mdi-clock-check-outline
                          </v-icon>
                        </v-chip>
                        <p
                          :class="`black--text font-weight-medium text-capitalize text-body-1 mb-0`"
                        >
                          {{
                            fullFormattedDate(
                              item.date,
                              "dddd D [de] MMMM [del] YYYY"
                            )
                          }}
                        </p>
                      </template>
                      <template v-slot:[`item.is_justified`]="{ item }">
                        <v-tooltip
                          bottom
                          :color="`${
                            isJustified(item) ? 'success' : 'red darken-1'
                          }`"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              @click="checkJustificationOrLicenseState(item)"
                              :color="`${
                                isJustified(item)
                                  ? 'green lighten-4'
                                  : 'red lighten-4'
                              }`"
                            >
                              <v-icon
                                :color="`${
                                  isJustified(item) ? 'success' : 'red darken-1'
                                }`"
                                center
                              >
                                {{
                                  isJustified(item)
                                    ? "mdi-check-circle-outline"
                                    : "mdi-account-badge-outline"
                                }}
                              </v-icon>
                            </v-chip>
                          </template>
                          <span>{{
                            isJustified(item)
                              ? "Llegada tarde justificada"
                              : "Llegada tarde sin justificar"
                          }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.schedule`]="{ item }">
                        <p
                          class="font-weight-medium text-capitalize text-body-1 mb-0"
                        >
                          <span>{{ item.schedule }}</span>
                        </p></template
                      >
                      <template v-slot:[`item.userData.name`]="{ item }">
                        <p
                          class="font-weight-medium text-capitalize text-body-1 mb-0"
                        >
                          <span class="mt-2 ml-3">{{
                            item.userData.name
                          }}</span>
                        </p></template
                      >
                      <template v-slot:[`item.id`]="{ item }">
                        <v-tooltip
                          v-if="existInArray('Eliminar', currentPageActions)"
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="red darken-1"
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteArrivalToClass(item)"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Eliminar llegada tarde a clase</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card>

                  <v-alert
                    class="mt-4"
                    v-else
                    color="green"
                    dense
                    outlined
                    prominent
                    text
                    type="success"
                    >No cuenta con llegadas tarde a clases.</v-alert
                  >
                </v-tab-item>
                <!-- end::1st tab - Late arrivals to class -->

                <!-- begin::2nd tab - Late arrivals to school -->
                <v-tab-item>
                  <v-card
                    v-if="allLateArrivalsToSchoolRecordsSorted.length > 0"
                    class="elevation-0"
                  >
                    <v-card-title class="mb-6">
                      <v-text-field
                        v-model="searchToSchool"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        rounded
                        filled
                        dense
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      class="elevation-0"
                      elevation="0"
                      :headers="headersLateArrivalsToSchool"
                      :items="allLateArrivalsToSchoolRecordsSorted"
                      :search="searchToSchool"
                    >
                      <template v-slot:[`item.date`]="{ item }">
                        <p
                          :class="`${colorTheme}--text text--lighten-1 font-weight-medium text-capitalize text-body-1 mb-0`"
                        >
                          {{
                            fullFormattedDate(
                              item.date,
                              "dddd D [de] MMMM [del] YYYY"
                            )
                          }}
                        </p>
                      </template>
                      <template v-slot:[`item.late_arrival_time`]="{ item }">
                        <p :class="`font-weight-bold text-body-1 mb-0`">
                          {{ item.late_arrival_time }}
                        </p>
                      </template>
                      <template v-slot:[`item.is_justified`]="{ item }">
                        <v-tooltip
                          bottom
                          :color="`${
                            isJustified(item) ? 'success' : 'red darken-1'
                          }`"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              @click="checkJustificationOrLicenseState(item)"
                              :color="`${
                                isJustified(item)
                                  ? 'green lighten-4'
                                  : 'red lighten-4'
                              }`"
                            >
                              <v-icon
                                :color="`${
                                  isJustified(item) ? 'success' : 'red darken-1'
                                }`"
                                center
                              >
                                {{
                                  isJustified(item)
                                    ? "mdi-check-circle-outline"
                                    : "mdi-account-badge-outline"
                                }}
                              </v-icon>
                            </v-chip>
                          </template>
                          <span>{{
                            isJustified(item)
                              ? "Llegada tarde justificada"
                              : "Llegada tarde sin justificar"
                          }}</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.user_data.name`]="{ item }">
                        <p
                          class="font-weight-medium text-capitalize text-body-1 mb-0"
                        >
                          <span class="mt-2 ml-3 ml-sm-0">{{
                            item.user_data.name
                          }}</span>
                        </p></template
                      >
                      <template v-slot:[`item.id`]="{ item }">
                        <v-tooltip
                          v-if="existInArray('Eliminar', currentPageActions)"
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="red darken-1"
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteArrivalToSchool(item)"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Eliminar llegada tarde a la institución</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-card>

                  <v-alert
                    class="mt-4"
                    v-else
                    color="green"
                    dense
                    outlined
                    prominent
                    text
                    type="success"
                    >No cuenta con llegadas tarde a la institución.</v-alert
                  >
                </v-tab-item>
                <!-- end::2nd tab - Late arrivals to school -->
              </v-tabs-items>

              <!--end:: tabs-->
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <JustificationHeaderModal
      ref="mdlJustificationOrLicenceDetails"
      :titleLabel="JustificationOrLicenceDetailsModalTitle"
      :headerData="selectedLateArrivalRowDetails"
    >
    </JustificationHeaderModal>
  </v-dialog>
</template>

<script>
import JustificationHeaderModal from "@/components/modals/justification_permission/JustificationHeaderModal";

// Repositories
import studentClassRepository from "@/repositories/studentClassRepository";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "LateArrivalsDetailsModal",
  components: {
    JustificationHeaderModal,
  },

  mounted() {
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Panel disciplinario");
  },
  props: {
    lateArrivals: {
      type: Object,
    },
    selectedStage: {
      type: Object,
    },
    dateRange: {
      type: Object,
    },
    colorTheme: {
      type: String,
    },
  },
  data() {
    return {
      JustificationOrLicenceDetailsModalTitle: "",
      dialogActiveState: false,
      loadingMode: false,
      searchToClass: "",
      searchToSchool: "",
      tabs: [],
      headersLateArrivalsToSchool: [
        {
          text: "Día de aplicación",
          value: "date",
          groupable: false,
        },
        {
          text: "Hora de llegada",
          value: "late_arrival_time",
          groupable: false,
        },
        {
          text: "Aplicada por",
          value: "user_data.name",
          groupable: false,
        },
        {
          text: "Estado",
          value: "is_justified",
          groupable: false,
        },
        {
          text: "Opciones",
          value: "id",
          groupable: false,
        },
      ],
      headersLateArrivalsToClass: [
        {
          text: "Día de aplicación",
          value: "date",
          groupable: false,
        },
        {
          text: "Aplicada por",
          value: "user_data.name",
          groupable: false,
        },
        {
          text: "Asignatura/Módulo",
          value: "subject",
          groupable: false,
        },
        {
          text: "Hora de clase",
          value: "schedule",
          groupable: false,
        },
        {
          text: "Estado",
          value: "is_justified",
          groupable: false,
        },
        {
          text: "Opciones",
          value: "id",
          groupable: false,
        },
      ],
      selectedLateArrivalRowDetails: {},
    };
  },
  methods: {
    toggleModal() {
      this.dialogActiveState = !this.dialogActiveState;
    },
    isJustified(item) {
      return (
        item.is_justified &&
        item.is_justified != null &&
        item.justification_header != null
      );
    },
    isLicence(record) {
      if (this.isJustified(record)) {
        return record.justification_header.student_request_type_id === 1;
      }
      return false;
    },
    showMdlJustificationOrLicenceDetails() {
      this.$refs.mdlJustificationOrLicenceDetails.toggleModal();
    },

    checkJustificationOrLicenseState(selectedRowDetails) {
      this.selectedLateArrivalRowDetails = {};
      this.selectedLateArrivalRowDetails = {
        ...selectedRowDetails.justification_header,
      };

      if (this.isJustified(selectedRowDetails)) {
        this.JustificationOrLicenceDetailsModalTitle = this.isLicence(
          selectedRowDetails
        )
          ? "Permiso para llegada tarde"
          : "Llegada tarde justificada";
        this.showMdlJustificationOrLicenceDetails();
      }
    },
    deleteArrivalToSchool(item) {
      Swal.fire({
        title: "Eliminar llegada tarde a la institución",
        text: "Estás a punto de eliminar la llegada tarde ¿Estás seguro de continuar?",
        icon: "warning",
        width: 550,
        showCancelButton: true,
        denyButtonColor: "#F44336",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadingMode = true;
          studentClassRepository
            .deleteStudentClass(item.id)
            .then((response) => {
              if (item.is_justified) {
                const index =
                  this.lateArrivals.to_school.justified.indexOf(item);
                this.$emit("removeAbsence", {
                  type: "to_school",
                  status: "justified",
                  index: index,
                });
              }
              if (!item.is_justified) {
                const index =
                  this.lateArrivals.to_school.unjustified.indexOf(item);
                this.$emit("removeAbsence", {
                  type: "to_school",
                  status: "unjustified",
                  index: index,
                });
              }
              this.fireToast({
                title: "LLegada tarde a la institución eliminada con éxito",
                icon: "success",
              });
            })
            .catch((e) => {
              this.fireToast({
                title: "Ocurrió un error al eliminar la llegada tarde",
                icon: "error",
              });
            })
            .finally(() => {
              this.loadingMode = false;
              this.dialogActiveState = true;
            });
        }
      });
    },
    deleteArrivalToClass(item) {
      Swal.fire({
        title: "Eliminar llegada tarde a clases",
        text: "Estás a punto de eliminar la llegada tarde ¿Estás seguro de continuar?",
        icon: "warning",
        width: 550,
        showCancelButton: true,
        denyButtonColor: "#F44336",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadingMode = true;
          studentClassRepository
            .deleteLateArrivalToClass(item.id)
            .then((response) => {
              if (item.is_justified) {
                const index =
                  this.lateArrivals.to_class.justified.indexOf(item);
                this.$emit("removeAbsence", {
                  type: "to_class",
                  status: "justified",
                  index: index,
                });
              }
              if (!item.is_justified) {
                const index =
                  this.lateArrivals.to_class.unjustified.indexOf(item);
                this.$emit("removeAbsence", {
                  type: "to_class",
                  status: "unjustified",
                  index: index,
                });
              }
              this.fireToast({
                title: "LLegada tarde a clases eliminada con éxito",
                icon: "success",
              });
            })
            .catch((e) => {
              this.fireToast({
                title: "Ocurrió un error al eliminar la llegada tarde",
                icon: "error",
              });
            })
            .finally(() => {
              this.loadingMode = false;
              this.dialogActiveState = true;
            });
        }
      });
    },
  },
  computed: {
    allLateArrivalsToClassRecordsSorted() {
      let allRecords = [];

      // Checking if there are not undefined properties
      if (this.correctClassObjectStructure) {
        allRecords = this.lateArrivals.to_class?.justified.concat(
          this.lateArrivals.to_class?.unjustified
        );
      }

      return this.mergeSort(allRecords, "date", "desc", "date");
    },

    allLateArrivalsToSchoolRecordsSorted() {
      let allRecords = [];

      // Checking if there are not undefined properties
      if (this.correctSchoolObjectStructure) {
        allRecords = this.lateArrivals.to_school?.justified.concat(
          this.lateArrivals.to_school?.unjustified
        );
      }

      return this.mergeSort(allRecords, "date", "desc", "date");
    },

    // verifying main object and inner objects are not null and undefined
    correctSchoolObjectStructure() {
      return (
        this.lateArrivals?.to_school?.justified !== undefined &&
        this.lateArrivals.to_school.justified !== null &&
        this.lateArrivals.to_school.unjustified !== undefined &&
        this.lateArrivals.to_school.unjustified !== null
      );
    },

    correctClassObjectStructure() {
      return (
        this.lateArrivals?.to_class?.justified !== undefined &&
        this.lateArrivals.to_class.justified !== null &&
        this.lateArrivals.to_class.unjustified !== undefined &&
        this.lateArrivals.to_class.unjustified !== null
      );
    },

    ...mapGetters(["currentPageActions"]),
  },

  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
