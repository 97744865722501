import { PedagogicalDiaryService } from "@/core/services/apis.service";

/**
 * POST request to create justification header
 * @param payload
 * @returns {*}
 */
export const createJustification = (payload) => {
  return PedagogicalDiaryService.post(
    "student-justifications-permissions/store-justification-by-justification-header",
    payload
  );
};

export const getJustifications = (payload) => {
  return PedagogicalDiaryService.post(
    "justification-permission-headers/report",
    payload
  );
};

export const getPermissions = (payload) => {
  return PedagogicalDiaryService.post(
    "justification-permission-headers/permission-report",
    payload
  );
};

export const deletePermission = (id, studentId) => {
  return PedagogicalDiaryService.delete(
    `justification-permission-headers/delete-permission/${id}/student/${studentId}`
  );
};

export const getJustificationsByRangeDate = (
  startDate,
  endDate,
  studentRequestType
) => {
  return PedagogicalDiaryService.get(
    `justification-permission-headers/by-date-range/start-date/${startDate}/end-date/${endDate}/student-request-type/${studentRequestType}`
  );
};

export const deleteJustifications = (payload) => {
  return PedagogicalDiaryService.post(
    "student-justifications-permissions/remove-student-justification-permission",
    payload
  );
};

export default {
  createJustification,
  getJustifications,
  deleteJustifications,
  getJustificationsByRangeDate,
  getPermissions,
  deletePermission,
};
