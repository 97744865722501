<template>
  <v-menu
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :nudge-right="40"
    v-model="showCalendar"
    :disabled="isDisabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        v-on="on"
        readonly
        filled
        rounded
        :value="visibleLabel"
        :label="labelText"
        :prepend-icon="prependIcon"
        :prepend-inner-icon="prependInnerIcon"
        :error-messages="errorMessages"
        :disabled="isDisabled"
      >
      </v-text-field>
    </template>
    <v-date-picker
      v-model="optionSelected"
      :allowed-dates="allowDates"
      @input="executeReturnMethod()"
      @blur="executeValidation()"
      :disabled="isDisabled"
    ></v-date-picker>
    
  </v-menu>
  
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: "DateDropdownMenu",
  props: {
    prependIcon: {
      type: String,
      default: "",
    },
    prependInnerIcon: {
      type: String,
      default: ''
    },
    preSelectedOption: {
      type: String,
      default: null,
    },
    validationMethod: {
      type: Object,
      default: null,
    },
    allowDates: {
      type: Function,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    clockFormat: {
      type: String,
      default: "ampm",
    },
    inputMethod: {
        type: Function,
        required: true
    },
    errorMessages: {
      type: Array,
      default() {
        return []
      }
    },
    labelText: {
      type: String,
      required: true
    }
  },
  data: () => ({
    optionSelected: "",
    showCalendar: false, // Deploy dropmenu
  }),
  methods: {
    executeValidation() {
      if (this.validationMethod != null) {
        this.validationMethod.$touch();
      }
    },
    executeReturnMethod() {
      // Send new value
      this.inputMethod(this.optionSelected);
      this.showCalendar = false;
    },
    checkPreSelectedOption() {
      const numberDay = dayjs(this.preSelectedOption).day();
      // Si es sábado, se resta un día para que el valor por defecto sea viernes
      if (numberDay == 6) {
        this.optionSelected = dayjs(this.preSelectedOption).subtract(1, 'day').format("YYYY-MM-DD");
        return;
      }
      // Si hoy es domingo, se restan dos días para que el valor por defecto sea viernes
      if (numberDay == 0) {
        this.optionSelected = dayjs(this.preSelectedOption).subtract(2, 'day').format("YYYY-MM-DD");
        return;
      }
      this.optionSelected = this.preSelectedOption;
    },
    resetStartValidations() {
      this.optionSelected = null;
    },
  },
  computed: {
    visibleLabel() {
      if (this.optionSelected == null || this.optionSelected == "") {
        return "Selecciona una fecha";
      }
      return this.optionSelected;
    }
  },
  mounted() {
    this.resetStartValidations();
    this.checkPreSelectedOption();
  },
  watch: {
    preSelectedOption: function(newValue) {
      // Si es sábado, se resta un día para que el valor por defecto sea viernes
      if (dayjs(newValue).day() == 0) {
        this.optionSelected = dayjs(newValue).subtract(1, 'day').format("YYYY-MM-DD");
        return;
      }
      // Si hoy es domingo, se restan dos días para que el valor por defecto sea viernes
      if (dayjs(newValue).day() == 6) {
        this.optionSelected = dayjs(newValue).subtract(2, 'day').format("YYYY-MM-DD");
        return;
      }
      this.optionSelected = this.preSelectedOption;
    }
  }
};
</script>